import React, { useState } from "react";
import {
  Button,
  Card,
  Empty,
  Flex,
  List,
  notification,
  Skeleton,
  Space,
  Typography,
  theme,
  Badge,
} from "antd";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { fieldTestOutcomeConfigurations } from "../../../../../../util/data_hooks";
import {
  FieldConfigurationPlaygroundResponse,
  FieldTestOutcomeConfigurationWithMeeting,
} from "../../../../../../util/types";
import {
  EditOutlined,
  LoadingOutlined,
  PlayCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { TestPreview } from "./TestPreview";
import { TestOutcomeConfigDrawer } from "./TestOutcomeConfigDrawer";
import {
  createTestLibraryTest,
  pollTestLibraryTest,
} from "../../../../../../util/api";
import { get, isEmpty, isNil } from "lodash";
import { ResultPreview } from "./ResultPreview";
import { ReviewResultDrawer } from "./ReviewResultDrawer";
import { setLatestLibraryTestResult } from "../../../../../redux/features/PlaygroundFieldTesting/playgroundSlice";
import { configHasErrors } from "./libraryHelper";

export function TestLibrary() {
  const dispatch = useAppDispatch();
  const fieldToTest = useAppSelector((state) => state.playground.fieldToTest);
  const fieldDefinition = useAppSelector(
    (state) => state.playground.fieldDefinition
  );
  const [notificationApi, contextHolder] = notification.useNotification();
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [isReviewResultDrawerOpen, setIsReviewResultDrawerOpen] =
    useState(false);
  const [isExecutingTest, setIsExecutingTest] = useState(false);
  const [isTestExecuting, setIsTestExecuting] = useState<{
    [testConfigurationUuid: string]: boolean;
  }>({});
  const [currentTestConfigUuid, setCurrentTestConfigUuid] = useState<
    string | null
  >(null);
  const {
    fieldTestOutcomeConfigurations: testConfigs,
    isLoading: testConfigsLoading,
    mutate: refetchTestConfigs,
  } = fieldTestOutcomeConfigurations(fieldToTest);
  const testResults = useAppSelector(
    (state) => state.playground.latestLibraryTestResult
  );
  const setTestResults = (result: {
    [testConfigurationUuid: string]: FieldConfigurationPlaygroundResponse;
  }) => dispatch(setLatestLibraryTestResult(result));
  const { token } = theme.useToken();

  const doFieldConfigurationHaveErrors = testConfigs.some((testConfig) =>
    configHasErrors(fieldDefinition, fieldToTest, testConfig)
  );

  const setPartialResults = (partialResults: {
    [testConfigurationUuid: string]: FieldConfigurationPlaygroundResponse;
  }) => {
    setTestResults(partialResults);
    Object.entries(partialResults)
      .filter(([, value]) => !isNil(value))
      .forEach(([key]) => {
        setIsTestExecuting((prev) => ({
          ...prev,
          [key]: false,
        }));
      });
  };

  const executeTest = async () => {
    setIsExecutingTest(true);
    setIsTestExecuting(
      testConfigs.reduce((acc, testConfig) => {
        acc[testConfig.uuid] = true;
        return acc;
      }, {})
    );
    try {
      const { job_id } = await createTestLibraryTest(
        fieldToTest.uuid,
        fieldToTest
      );
      const { results } = await pollTestLibraryTest(
        fieldToTest.uuid,
        setPartialResults,
        job_id,
        15,
        1000
      );
      setIsTestExecuting(
        testConfigs.reduce((acc, testConfig) => {
          acc[testConfig.uuid] = false;
          return acc;
        }, {})
      );
      setTestResults(results);
    } catch (err) {
      if (get(err, "response.status", 500) < 500) {
        showError(
          get(
            err,
            "response.data.error",
            "Ooops, something is wrong on our end! Please try again later."
          )
        );
      } else {
        console.log(err);
        showError(
          "Ooops, something is wrong on our end! Please try again later."
        );
      }
    } finally {
      setIsExecutingTest(false);
    }
  };

  const showError = (message: string) => {
    notificationApi["error"]({
      message: "Could not complete test",
      description: message,
      duration: 0,
    });
  };

  const meetingTestActions = (
    testConfig: FieldTestOutcomeConfigurationWithMeeting
  ) => {
    const hasErrors = configHasErrors(fieldDefinition, fieldToTest, testConfig);

    const actions = [
      <Badge
        count={
          hasErrors ? (
            <ExclamationCircleOutlined style={{ color: token.colorError }} />
          ) : (
            0
          )
        }
        key={"edit"}
      >
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            setCurrentTestConfigUuid(testConfig.uuid);
            setIsEditDrawerOpen(true);
          }}
        />
      </Badge>,
    ];

    return actions;
  };

  const didPass = (testConfig: FieldTestOutcomeConfigurationWithMeeting) => {
    return Object.values(
      testResults[testConfig.uuid]?.meta?.comparison_results
    ).every((result) => result.passed !== false);
  };

  const didError = (testConfig: FieldTestOutcomeConfigurationWithMeeting) =>
    testResults[testConfig.uuid]?.error == true;

  if (testConfigsLoading) {
    return <Skeleton active />;
  }

  return (
    <>
      {contextHolder}
      <Card
        title={"Test Meetings"}
        extra={
          <Button
            type={"primary"}
            onClick={executeTest}
            loading={isExecutingTest}
            icon={<PlayCircleOutlined />}
            disabled={
              testConfigs.length === 0 || doFieldConfigurationHaveErrors
            }
          >
            Run Tests
          </Button>
        }
      >
        {testConfigs && testConfigs.length > 0 && (
          <>
            <List<FieldTestOutcomeConfigurationWithMeeting>
              itemLayout={"horizontal"}
              dataSource={testConfigs}
              footer={
                <Typography.Text type={"secondary"} italic>
                  Run a focused test to add more meetings
                </Typography.Text>
              }
              renderItem={(testConfig) => {
                return (
                  <List.Item actions={meetingTestActions(testConfig)}>
                    <List.Item.Meta
                      title={
                        isTestExecuting[testConfig.uuid] ||
                        didError(testConfig) ? (
                          <span>
                            {" "}
                            {/* Non-clickable when test is executing or error*/}
                            {isTestExecuting[testConfig.uuid] && (
                              <LoadingOutlined
                                style={{ marginRight: "0.5rem" }}
                              />
                            )}
                            {didError(testConfig) && (
                              <ExclamationCircleOutlined
                                style={{
                                  color: token.colorWarning,
                                  fontSize: "16px",
                                  marginRight: "0.5rem",
                                }}
                              />
                            )}
                            {testConfig.meeting.topic}
                          </span>
                        ) : (
                          <a
                            onClick={() => {
                              if (!didError(testConfig)) {
                                setCurrentTestConfigUuid(testConfig.uuid);
                                if (isEmpty(testResults)) {
                                  setIsEditDrawerOpen(true);
                                } else {
                                  setIsReviewResultDrawerOpen(true);
                                }
                              }
                            }}
                          >
                            {!isEmpty(testResults[testConfig.uuid]) &&
                              (didPass(testConfig) ? (
                                <CheckCircleOutlined
                                  style={{
                                    color: token.colorSuccess,
                                    fontSize: "16px",
                                    marginRight: "0.5rem",
                                  }}
                                />
                              ) : (
                                <CloseCircleOutlined
                                  style={{
                                    color: token.colorError,
                                    fontSize: "16px",
                                    marginRight: "0.5rem",
                                  }}
                                />
                              ))}
                            {testConfig.meeting.topic}
                          </a>
                        )
                      }
                      description={
                        isEmpty(testResults[testConfig.uuid]) &&
                        !isTestExecuting[testConfig.uuid] ? (
                          <Flex align={"center"} gap={"small"}>
                            Preview
                            <TestPreview
                              fieldDefinition={fieldDefinition}
                              fieldConfiguration={fieldToTest}
                              testConfig={testConfig}
                              onClick={() => {
                                setCurrentTestConfigUuid(testConfig.uuid);
                                setIsEditDrawerOpen(true);
                              }}
                            />
                          </Flex>
                        ) : (
                          <Flex align={"center"} gap={"small"}>
                            Result
                            <ResultPreview
                              isLoading={isTestExecuting[testConfig.uuid]}
                              testResults={testResults[testConfig.uuid]}
                            />
                          </Flex>
                        )
                      }
                    />
                  </List.Item>
                );
              }}
            />
          </>
        )}
        {testConfigs.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <Space.Compact direction={"vertical"} size={"small"}>
                <Typography.Text type={"secondary"}>
                  No Configured Test Meetings
                </Typography.Text>
                <Typography.Text type={"secondary"}>
                  Run a focused test to add one
                </Typography.Text>
              </Space.Compact>
            }
          />
        )}
      </Card>
      {isEditDrawerOpen && (
        <TestOutcomeConfigDrawer
          currentTestConfigUuid={currentTestConfigUuid}
          testConfigs={testConfigs}
          open={isEditDrawerOpen}
          setOpen={setIsEditDrawerOpen}
          afterSave={refetchTestConfigs}
          afterDelete={refetchTestConfigs}
        />
      )}
      {isReviewResultDrawerOpen && (
        <ReviewResultDrawer
          open={isReviewResultDrawerOpen}
          setOpen={setIsReviewResultDrawerOpen}
          testResult={testResults[currentTestConfigUuid]}
          fieldDefinition={fieldDefinition}
          fieldToTest={testConfigs.find(
            (config) => config.uuid === currentTestConfigUuid
          )}
        />
      )}
    </>
  );
}
