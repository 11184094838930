import React, { useState } from "react";
import { Card, Segmented, Typography } from "antd";
import { PlayCircleOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { PlaygroundTestType } from "../../../../../util/enums";
import { SingleTest } from "./single_test/SingleTest";
import { TestLibrary } from "./test_library/TestLibrary";
import { useFlags } from "launchdarkly-react-client-sdk";

export function TestingCard() {
  const [selectedTestType, setSelectedTestType] = useState<PlaygroundTestType>(
    PlaygroundTestType.Single
  );
  const { testingLibrary: testingLibraryEnabledFlag } = useFlags();

  return (
    <>
      <Card
        title="Test"
        extra={
          testingLibraryEnabledFlag && (
            <Segmented<PlaygroundTestType>
              value={selectedTestType}
              onChange={setSelectedTestType}
              options={[
                {
                  label: <Typography.Text>Focused Test </Typography.Text>,
                  value: PlaygroundTestType.Single,
                  icon: <PlayCircleOutlined />,
                },
                {
                  label: <Typography.Text>Test Library</Typography.Text>,
                  value: PlaygroundTestType.Library,
                  icon: <MenuUnfoldOutlined />,
                },
              ]}
            />
          )
        }
      >
        {selectedTestType === PlaygroundTestType.Single && <SingleTest />}
        {selectedTestType === PlaygroundTestType.Library && <TestLibrary />}
      </Card>
    </>
  );
}
