import React from "react";
import { Form, Input, Table } from "antd";

import { PicklistDefinition, PicklistItem } from "../../../../../util/types";
import { ColumnsType } from "antd/es/table";

interface Props {
  picklistDefinitions: PicklistDefinition[];
  picklists: PicklistItem[];
  isPicklistRequired: boolean;
}

export function PicklistTable({
  picklists,
  picklistDefinitions,
  isPicklistRequired,
}: Props) {
  const columns: ColumnsType<PicklistItem> = [
    {
      title: "Name",
      render: (_, record) => {
        const picklistDefinition = picklistDefinitions.find(
          (picklist) => picklist.uuid === record.field_definition_picklist_uuid
        );

        return picklistDefinition.label;
      },
      key: "name",
      width: "15%",
    },
    {
      key: "Description",
      title: "Description",
      render: (_, record) => {
        const picklistDefinition = picklistDefinitions.find(
          (picklist) => picklist.uuid === record.field_definition_picklist_uuid
        );
        return (
          <Form.Item
            name={record.uuid}
            style={{ margin: 0 }}
            rules={[
              {
                required: isPicklistRequired && picklistDefinition?.active,
              },
            ]}
          >
            <Input.TextArea autoSize={{ maxRows: 10 }} />
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Table
      dataSource={picklists}
      columns={columns}
      pagination={false}
      rowKey="uuid"
      style={{
        marginBottom: "1rem",
        marginTop: "1rem",
      }}
    />
  );
}
