import { Button, message } from "antd";
import React, { useState } from "react";
import { Col, Drawer, Row, Space, Typography, Modal, theme } from "antd";
import { LeftOutlined, RightOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  FieldTestOutcomeConfigurationDetail,
  FieldTestOutcomeConfigurationWithMeeting,
} from "../../../../../../util/types";
import { TestOutcomeConfigForm } from "./TestOutcomeConfigForm";
import {
  deleteFieldTestConfiguration,
  updateFieldTestConfiguration,
} from "../../../../../../util/api";
import { get } from "lodash";
import { useAppSelector } from "../../../../../redux/hooks";

interface Props {
  currentTestConfigUuid: string;
  testConfigs: FieldTestOutcomeConfigurationWithMeeting[];
  open: boolean;
  setOpen: (value: boolean) => void;
  afterSave: () => void;
  afterDelete: () => void;
}

export function TestOutcomeConfigDrawer({
  currentTestConfigUuid,
  testConfigs,
  open,
  setOpen,
  afterSave,
  afterDelete,
}: Props) {
  const { token } = theme.useToken();
  // STATE
  const fieldToTest = useAppSelector((state) => state.playground.fieldToTest);
  const [currentIndex, setCurrentIndex] = useState<number>(
    testConfigs?.findIndex(({ uuid }) => uuid === currentTestConfigUuid)
  );

  const [fieldTestOutcomeConfiguration, setFieldTestOutcomeConfiguration] =
    useState<FieldTestOutcomeConfigurationWithMeeting | null>(
      testConfigs?.[currentIndex]
    );

  const setCurrentTestConfig = (index: number) => {
    setCurrentIndex(index);
    setFieldTestOutcomeConfiguration(testConfigs?.[index]);
  };

  const updateTestConfig = async (
    values: FieldTestOutcomeConfigurationDetail
  ) => {
    try {
      await updateFieldTestConfiguration(
        fieldToTest?.uuid,
        fieldTestOutcomeConfiguration?.uuid,
        values
      );
      message.success(
        `${fieldTestOutcomeConfiguration?.meeting.topic} test updated`
      );
      afterSave();
      setOpen(false);
    } catch (error) {
      if (get(error, "response.status") < 500) {
        message.error(
          get(
            error,
            "response.data.message",
            "Oops! Something went wrong. Please try again."
          )
        );
      } else {
        message.error("Oops! Something went wrong. Please try again.");
      }
    }
  };

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: `Delete ${fieldTestOutcomeConfiguration?.meeting.topic} Test?`,
      icon: <DeleteOutlined style={{ color: token.colorError }} />,
      content: "Field configuration test will be deleted.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await deleteFieldTestConfiguration(
            fieldToTest?.uuid,
            fieldTestOutcomeConfiguration?.uuid
          );
          message.success(
            `${fieldTestOutcomeConfiguration?.meeting.topic} test deleted`
          );
          afterDelete();
          setOpen(false);
        } catch (err) {
          console.error(err);
          const defaultMessage =
            "Oops, something is wrong on our end! Please try again later.";

          const is500 = get(err, "response.status", 500) >= 500;

          message.error(
            is500
              ? defaultMessage
              : get(err, "response.data.error", defaultMessage)
          );
        }
      },
    });
  };

  return (
    <Drawer
      destroyOnClose
      title={
        <Row align="middle" justify="space-between">
          <Col>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {fieldTestOutcomeConfiguration?.meeting.topic}
            </Typography.Title>
          </Col>

          <Col>
            <Space>
              <Button
                danger
                onClick={showDeleteConfirm}
                icon={<DeleteOutlined />}
              >
                Delete{" "}
              </Button>
              <Button
                disabled={currentIndex === 0}
                onClick={() => {
                  setCurrentTestConfig(currentIndex - 1);
                }}
                shape="circle"
                icon={<LeftOutlined />}
              />

              <Button
                onClick={() => {
                  setCurrentTestConfig(currentIndex + 1);
                }}
                disabled={currentIndex === testConfigs?.length - 1}
                shape="circle"
                icon={<RightOutlined />}
              />
            </Space>
          </Col>
        </Row>
      }
      placement="right"
      width="75rem"
      open={open}
      onClose={() => setOpen(false)}
      afterOpenChange={(open) => !open}
    >
      <TestOutcomeConfigForm
        isCreate={false}
        testConfiguration={fieldTestOutcomeConfiguration}
        handleSave={updateTestConfig}
        handleCancel={() => setOpen(false)}
      />
    </Drawer>
  );
}
