import React, { useState } from "react";
import { Button, Col, Drawer, Row, Space, Typography, message } from "antd";
import {
  fetchDeleteWorkflowState,
  fetchUpdateWorkflowState,
} from "../../../../util/api";
import { WorkflowActionType } from "../../../../util/enums";
import { UpdateCRMRecord } from "../action_editors/UpdateCRMRecord";
import { useNavigate, useParams } from "react-router-dom";
import { useWorkflow, useWorkflowState } from "../../../../util/data_hooks";
import { SendSlackMessage } from "../action_editors/SendSlackMessage";
import { CreateSlackChannel } from "../action_editors/CreateSlackChannel";
import { SendTeamsMessage } from "../action_editors/SendTeamsMessage";
import { GenerateContent } from "../action_editors/GenerateContent";
import { WorkflowActionTypeIcon } from "../../../shared/WorkflowActionTypeIcon";
import { DeleteOutlined } from "@ant-design/icons";
import { CreateCRMRecord } from "../action_editors/CreateCRMRecord";
import { getFormattedDate } from "../../../../helpers/date_helpers";
import { CheckCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import {
  useWorkflowStateContext,
  WorkflowStateProvider,
} from "../action_editors/shared/WorkflowStateContext";
import { green } from "@ant-design/colors";
import SkeletonInput from "antd/es/skeleton/Input";

export const DEBOUNCE_DELAY = 1000;

function ActionEditor() {
  const { workflowState } = useWorkflowStateContext();

  switch (workflowState.actionable_type) {
    case WorkflowActionType.UpdateCRMRecord:
      return <UpdateCRMRecord />;
    case WorkflowActionType.CreateCRMRecord:
      return <CreateCRMRecord />;
    case WorkflowActionType.GenerateContent:
      return <GenerateContent />;
    case WorkflowActionType.CreateSlackChannel:
      return <CreateSlackChannel />;
    case WorkflowActionType.SendSlackMessage:
      return <SendSlackMessage />;
    case WorkflowActionType.SendTeamsMessage:
      return <SendTeamsMessage />;
    default:
      return null;
  }
}

export function WorkflowStateEditorDrawer() {
  const navigate = useNavigate();

  const { workflowUuid, workflowStateUuid } = useParams();

  const {
    workflow,
    isLoading: workflowLoading,
    mutate: mutateWorkflow,
  } = useWorkflow(workflowUuid);

  const {
    workflowState,
    isLoading: workflowStateLoading,
    mutate: mutateWorkflowState,
  } = useWorkflowState(workflowUuid, workflowStateUuid);

  const [open, setOpen] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const isLoading = workflowLoading || workflowStateLoading;

  const handleClose = () => setOpen(false);

  const handleDelete = async () => {
    try {
      setIsSaving(true);
      await fetchDeleteWorkflowState(workflow.uuid, workflowState.uuid);
      await mutateWorkflow();
      message.success("Workflow Action Deleted");
      handleClose();
    } catch (e) {
      message.error(e.message);
    }

    setIsSaving(false);
  };

  return (
    <Drawer
      forceRender
      destroyOnClose
      width="80rem"
      open={open}
      onClose={handleClose}
      afterOpenChange={(open) =>
        !open &&
        navigate(`/dashboard/settings/workflows/${workflowUuid}/editor`)
      }
      title={
        isLoading ? (
          <SkeletonInput active />
        ) : (
          <Row align="middle" justify="space-between">
            <Col>
              <Row align="middle" gutter={[4, 0]}>
                <Col>
                  <WorkflowActionTypeIcon
                    actionType={workflowState?.actionable_type}
                    size="lg"
                  />
                </Col>

                <Col>
                  <Typography.Title
                    level={5}
                    style={{ margin: "0px 5px" }}
                    editable={{
                      onChange: async (value) => {
                        setIsSaving(true);

                        await fetchUpdateWorkflowState(
                          workflowUuid,
                          workflowStateUuid,
                          { name: value }
                        );

                        await mutateWorkflowState();

                        setIsSaving(false);
                      },
                    }}
                  >
                    {workflowState?.name}
                  </Typography.Title>
                </Col>
              </Row>
            </Col>

            <Col>
              <Button
                type="primary"
                danger
                onClick={handleDelete}
                disabled={isSaving}
                icon={<DeleteOutlined />}
              />
            </Col>
          </Row>
        )
      }
      footer={
        <Space size="small">
          {isSaving ? (
            <>
              <LoadingOutlined />
              <Typography.Text>Saving changes...</Typography.Text>
            </>
          ) : (
            <>
              <CheckCircleTwoTone twoToneColor={green["primary"]} />
              <Typography.Text>
                Last updated {getFormattedDate(workflowState?.updated_at)}
              </Typography.Text>
            </>
          )}
        </Space>
      }
      loading={isLoading}
    >
      <WorkflowStateProvider
        workflowState={workflowState}
        setIsSaving={setIsSaving}
        afterSave={async () => {
          await mutateWorkflow();
          await mutateWorkflowState();
        }}
      >
        <ActionEditor />
      </WorkflowStateProvider>
    </Drawer>
  );
}
