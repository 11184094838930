import axios from "axios";

import {
  Meeting,
  Organization,
  Product,
  User,
  FieldConfiguration,
  WorkflowState,
  Workflow,
  WorkflowTransition,
  AutoGeneratedField,
  FieldConfigurationPlaygroundResponse,
  Team,
  CRMRecord,
  CRMProvider,
  SyncedCRMObject,
  CRMField,
  FieldDefinition,
  FieldTestOutcomeConfiguration,
  FieldTestOutcomeConfigurationDetail,
} from "./types";
import { CRMFieldType, UserRole, WorkflowTriggerType } from "./enums";
import { UpdateCrmRecordAction } from "../src/settings/workflows/action_editors/UpdateCRMRecord";
import { CreateCrmRecordAction } from "../src/settings/workflows/action_editors/CreateCRMRecord";
import { SendSlackMessageAction } from "../src/settings/workflows/action_editors/SendSlackMessage";
import { CreateSlackChannelAction } from "../src/settings/workflows/action_editors/CreateSlackChannel";
import { SendTeamsMessageAction } from "../src/settings/workflows/action_editors/SendTeamsMessage";
import {
  GenerateContentAction,
  WorkflowActionFieldConfiguration,
} from "../src/settings/workflows/action_editors/GenerateContent";
import { WorkflowActionCrmField } from "../src/settings/workflows/action_editors/shared/WorkflowActionCrmFields";

export const replaceUndefinedValuesWithNull = (values: object) =>
  Object.entries(values).reduce((acc, [key, value]) => {
    acc[key] = value === undefined ? null : value;
    return acc;
  }, {});

export const fetchUpdateMeeting = async (
  meetingId: string,
  attributes: Partial<Meeting | { user_modified_topic: string }>
): Promise<Meeting> => {
  const { data } = await axios.put(`/api/meetings/${meetingId}`, {
    meeting: attributes,
  });

  return data;
};

export const triggerWorkflow = async (
  workflowTrigger: WorkflowTriggerType,
  workflowData
) => {
  const { data } = await axios.post(`/api/workflows/trigger`, {
    workflow_trigger: workflowTrigger,
    workflow_data: workflowData,
  });

  return data;
};

export const retryWorkflowInstance = async (
  workflowUuid: string,
  workflowInstanceUuid: string
) => {
  const { data } = await axios.post(
    `/api/workflows/${workflowUuid}/workflow_instances/${workflowInstanceUuid}/retry`
  );

  return data;
};

export const fetchCreateWorkflowTransition = async (
  workflow_transition: Partial<WorkflowTransition>
): Promise<WorkflowTransition> => {
  const { data } = await axios.post(`/api/workflow_transitions`, {
    workflow_transition,
  });

  return data;
};

export const fetchUpdateWorkflowTransition = async (
  workflowTransitionUuid: string,
  workflow_transition: Partial<WorkflowTransition>
): Promise<WorkflowTransition> => {
  const { data } = await axios.put(
    `/api/workflow_transitions/${workflowTransitionUuid}`,
    { workflow_transition }
  );

  return data;
};

export const fetchDeleteWorkflowTransition = async (
  workflowTransitionUuid: string
): Promise<boolean> => {
  const { status } = await axios.delete(
    `/api/workflow_transitions/${workflowTransitionUuid}`
  );

  return status === 204;
};

export const fetchCreateWorkflowState = async (
  workflowUuid: string,
  workflowState: Partial<WorkflowState>
): Promise<WorkflowState> => {
  const { data } = await axios.post(
    `/api/workflows/${workflowUuid}/workflow_states`,
    { workflow_state: workflowState }
  );

  return data;
};

export const fetchDeleteWorkflowState = async (
  workflowUuid: string,
  workflowStateUuid: string
): Promise<boolean> => {
  const { status } = await axios.delete(
    `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}`
  );

  return status === 204;
};

export const fetchUpdateWorkflowState = async (
  workflowUuid: string,
  workflowStateUuid: string,
  workflowState: Partial<WorkflowState>
): Promise<WorkflowState> => {
  const { data } = await axios.put(
    `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}`,
    { workflow_state: workflowState }
  );

  return data;
};

export const fetchCreateWorkflow = async (
  teamUuid: string,
  workflow: Partial<Workflow>
): Promise<Workflow> => {
  const { data } = await axios.post(`/api/workflows?team_uuid=${teamUuid}`, {
    workflow,
  });

  return data;
};

export const fetchUpdateWorkflow = async (
  workflowUuid: string,
  workflow: Partial<Workflow>
): Promise<Workflow> => {
  const { data } = await axios.put(`/api/workflows/${workflowUuid}`, {
    workflow,
  });

  return data;
};

export const fetchDeleteWorkflow = async (
  workflowUuid: string
): Promise<boolean> => {
  const { status } = await axios.delete(`/api/workflows/${workflowUuid}`);

  return status === 204;
};

export const fetchSignOut = async (authenticityToken: string) => {
  await axios.delete("/users/sign_out", {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    data: { authenticity_token: authenticityToken },
  });
};

export const createProduct = async (
  product: Partial<Product>
): Promise<Product> => {
  const { data } = await axios.post(`/api/products`, product);

  return data;
};

export const updateProduct = async (
  productUuid: string,
  product: Partial<Product>
): Promise<Product> => {
  const { data } = await axios.patch(`/api/products/${productUuid}`, product);
  return data;
};

export const deleteProduct = async (product: Product): Promise<void> => {
  await axios.delete(`/api/products/${product.uuid}`);
};

export const fetchCreateOrganization = async (
  organization: Partial<Organization>
): Promise<Organization> => {
  const { data } = await axios.post(`/api/organizations`, organization);
  return data;
};

export const fetchUpdateOrganization = async (
  organizationUuid: string,
  organization: Partial<Organization>
): Promise<Organization> => {
  const { data } = await axios.patch(
    `/api/organizations/${organizationUuid}`,
    organization
  );
  return data;
};

export const updateUser = async (
  userUuid: string,
  user: Partial<User>
): Promise<User> => {
  const { data } = await axios.patch(`/api/users/${userUuid}`, { user });
  return data;
};

export const impersonateUser = async (userUuid: string) => {
  await axios.post(`/api/users/${userUuid}/impersonate`);
};

export const stopImpersonatingUser = async () => {
  await axios.post(`/api/users/stop_impersonating`);
};

export const deactivateFieldDefinition = async (
  fieldDefinitionUuid: string
): Promise<FieldConfiguration> => {
  const { data } = await axios.patch(
    `/api/field_definitions/${fieldDefinitionUuid}/deactivate`
  );

  return data;
};

export const updateFieldConfiguration = async (
  fieldConfigurationUuid: string,
  updatePayload: Pick<
    FieldConfiguration,
    "notes" | "prompt" | "automated" | "chunk_strategy" | "visible"
  >
): Promise<FieldConfiguration> => {
  const { data } = await axios.patch(
    `/api/field_configurations/${fieldConfigurationUuid}`,
    updatePayload
  );

  return data;
};

export const refreshOrganizationCRMFields = async (): Promise<void> => {
  return await axios.post(`/api/organizations/sync_crm_fields`);
};

export const fetchUpdateMe = async (
  data: Partial<{ is_onboarded: boolean }>
): Promise<User> => {
  return await axios.put(`/api/users/update_me`, {
    user: data,
  });
};

export const fetchUpdateAutoGeneratedField = async (
  meetingUuid: string,
  autoGeneratedFieldUuid: string,
  attributes: Partial<AutoGeneratedField>
): Promise<AutoGeneratedField> => {
  const { data } = await axios.patch(
    `/api/meetings/${meetingUuid}/auto_generated_fields/${autoGeneratedFieldUuid}`,
    { auto_generated_field: attributes }
  );

  return data;
};

export const fetchBulkUpdateAutoGeneratedFields = async (
  meetingUuid: string,
  autoGeneratedFieldUuids: string[],
  attributes: Partial<AutoGeneratedField>
): Promise<AutoGeneratedField> => {
  const { data } = await axios.post(
    `/api/meetings/${meetingUuid}/auto_generated_fields/bulk_update`,
    {
      uuids: autoGeneratedFieldUuids,
      ...attributes,
    }
  );

  return data;
};

export const fetchRegeneratePredictions = async (
  meetingUuid: string
): Promise<User> => {
  return await axios.post(
    `/api/meetings/${meetingUuid}/regenerate_predictions`
  );
};

export const createFieldConfigurationPlaygroundTest = async (
  meetingUuid: string,
  fieldConfig: Partial<FieldConfiguration>
): Promise<{
  job_id: string;
}> => {
  const { data } = await axios.post(`/api/field_configuration_playground`, {
    meeting_uuid: meetingUuid,
    field_config: fieldConfig,
  });

  return data;
};

export const pollFieldConfigurationPlaygroundTest = async (
  jobId: string,
  maxRetries: number = 15, // Maximum number of retries
  initialDelay: number = 500 // Initial delay in milliseconds
): Promise<FieldConfigurationPlaygroundResponse> => {
  let retries = 0;
  let delay = initialDelay;

  while (retries < maxRetries) {
    const { data, status } = await axios.get(
      `/api/field_configuration_playground/${jobId}`
    );

    if (status === 202) {
      retries++;
      await new Promise((resolve) => setTimeout(resolve, delay));
      delay *= 1.46; // Exponential backoff - Under initial conditions this will result in a max delay of 2.5 minutes
    } else {
      return data; // Return data if status is not 202
    }
  }

  throw new Error("Max retries reached. The job is still processing.");
};

export const createTestLibraryTest = async (
  fieldConfigurationUuid: string,
  fieldConfig: Partial<FieldConfiguration>
): Promise<{
  job_id: string;
}> => {
  const { data } = await axios.post(
    `/api/field_configurations/${fieldConfigurationUuid}/test_library`,
    {
      field_config: fieldConfig,
    }
  );

  return data;
};

export const pollTestLibraryTest = async (
  fieldConfigurationUuid: string,
  sendPartialResults: (partialResults: {
    [testConfigurationUuid: string]: FieldConfigurationPlaygroundResponse;
  }) => void,
  jobId: string,
  maxRetries: number = 15,
  initialDelay: number = 500
): Promise<{
  results: {
    [testConfigurationUuid: string]: FieldConfigurationPlaygroundResponse;
  };
  status: "running" | "complete";
}> => {
  let retries = 0;
  let delay = initialDelay;

  while (retries < maxRetries) {
    const { data, status } = await axios.get(
      `/api/field_configurations/${fieldConfigurationUuid}/test_library/${jobId}`
    );

    if (status === 202) {
      sendPartialResults(data.results);
      retries++;
      await new Promise((resolve) => setTimeout(resolve, delay));
      delay *= 1.46; // Exponential backoff - Under initial conditions this will result in a max delay of 2.5 minutes
    } else {
      return data; // Return data if status is not 202
    }
  }

  throw new Error("Max retries reached. The job is still processing.");
};

export type TeamUpdatePayload = Pick<Team, "name" | "is_default"> & {
  crm_role_uuids?: string[];
  user_uuids?: string[];
};

export const createTeam = async (
  createPayload: TeamUpdatePayload
): Promise<Team> => {
  const { data } = await axios.post(`/api/teams`, createPayload);
  return data;
};

export const updateTeam = async (
  teamUuid: string,
  updatePayload: TeamUpdatePayload
): Promise<Team> => {
  const { data } = await axios.patch(`/api/teams/${teamUuid}`, updatePayload);
  return data;
};

export const fetchUpdateCRMRecord = async ({
  objectType,
  recordNameProperty,
  recordId,
  crmRecord,
  associatedCRMRecordUuid,
  meetingUuid,
}: {
  objectType: string;
  recordNameProperty: string;
  recordId: string;
  crmRecord: CRMRecord;
  associatedCRMRecordUuid?: string;
  meetingUuid?: string;
}): Promise<CRMRecord> => {
  let endpoint = `/api/crm/update_record`;

  if (associatedCRMRecordUuid && meetingUuid) {
    endpoint += `?associated_crm_record_uuid=${associatedCRMRecordUuid}&meeting_uuid=${meetingUuid}`;
  }

  const { data } = await axios.post(endpoint, {
    object_type: objectType,
    record_name_property: recordNameProperty,
    id: recordId,
    crm_record: crmRecord,
  });

  return data;
};

export const fetchCreateCRMRecord = async (
  objectType: string,
  recordNameProperty: string,
  crmRecord: CRMRecord
): Promise<CRMRecord> => {
  const { data } = await axios.post(`/api/crm/create_record`, {
    object_type: objectType,
    record_name_property: recordNameProperty,
    crm_record: crmRecord,
  });

  return data;
};

export const fetchAssociateCRMRecord = async (
  objectType: string,
  recordNameProperty: string,
  recordId: string,
  associatedObjectType: string,
  associatedRecordId: string
): Promise<CRMRecord> => {
  const { data } = await axios.post(`/api/crm/associate_record`, {
    object_type: objectType,
    record_name_property: recordNameProperty,
    id: recordId,
    associated_object_type: associatedObjectType,
    associated_id: associatedRecordId,
  });

  return data;
};

export const createFieldDefinition = async (params: {
  field_configuration_group_uuid: string;
  name: string;
  type: CRMFieldType;
  length?: number;
  picklist_options: string[];
}): Promise<FieldDefinition> => {
  const { data } = await axios.post(`/api/field_definitions`, params);
  return data;
};

export const editFieldDefinition = async (
  fieldDefinitionUuid: string,
  fieldDefinition: {
    name: string;
    length?: number;
    picklistOptions: string[];
  }
): Promise<FieldDefinition> => {
  const { data } = await axios.patch(
    `/api/field_definitions/${fieldDefinitionUuid}`,
    fieldDefinition
  );

  return data;
};

export const createFieldConfigurationGroup = async ({
  syncedCRMObjectUuid,
  label,
}: {
  syncedCRMObjectUuid: string;
  label: string;
}): Promise<any> => {
  const { data } = await axios.post(`/api/field_configuration_groups`, {
    synced_crm_object_uuid: syncedCRMObjectUuid,
    label,
  });

  return data;
};

export const deactivateFieldConfigurationGroup = async ({
  fieldConfigurationGroupUuid,
}: {
  fieldConfigurationGroupUuid: string;
}): Promise<any> => {
  const { data } = await axios.patch(
    `/api/field_configuration_groups/${fieldConfigurationGroupUuid}/deactivate`
  );

  return data;
};

export const createAssociatedCRMRecord = async ({
  meetingUuid,
  syncedCrmObjectUuid,
  crmRecordId,
  crmRecordName,
}: {
  meetingUuid: string;
  syncedCrmObjectUuid: string;
  crmRecordId: string;
  crmRecordName: string;
}): Promise<any> => {
  const { data } = await axios.post(
    `/api/meetings/${meetingUuid}/associated_crm_records`,
    {
      synced_crm_object_uuid: syncedCrmObjectUuid,
      crm_record_id: crmRecordId,
      crm_record_name: crmRecordName,
    }
  );

  return data;
};

export const getCRMRecordsByName = async (
  object_type: string,
  record_name_property: string,
  name: string
): Promise<Omit<CRMRecord, "crm_record_fields">[]> => {
  const encodedQuery = encodeURIComponent(name);
  let endpoint = `/api/crm/query_records_by_name?object_type=${object_type}&query=${encodedQuery}`;

  if (record_name_property) {
    endpoint += `&record_name_property=${record_name_property}`;
  }

  const { data } = await axios.get(endpoint);

  return data;
};

export const getCRMRecordById = async (
  object_type: string,
  id: string,
  record_name_property?: string
): Promise<CRMRecord> => {
  let endpoint = `/api/crm/find_record?object_type=${object_type}&id=${id}`;

  if (record_name_property) {
    endpoint += `&record_name_property=${record_name_property}`;
  }

  const { data } = await axios.get(endpoint);

  return data;
};

export const getCRMObjectFields = async (
  object_type: string
): Promise<CRMField[]> => {
  const endpoint = `/api/crm/object_fields?object_type=${object_type}&include_all_fields=true`;

  const { data } = await axios.get(endpoint);

  return data;
};

export const createSyncedCRMObject = async ({
  organizationUuid,
  crmProviderName,
  crmObjectType,
  crmObjectLabel,
  searchableCRMFieldName,
}: {
  organizationUuid: string;
  crmProviderName: CRMProvider;
  crmObjectType: string;
  crmObjectLabel: string;
  searchableCRMFieldName: string;
}): Promise<SyncedCRMObject> => {
  const { data } = await axios.post(
    `/api/organizations/${organizationUuid}/synced_crm_objects`,
    {
      crm_provider_name: crmProviderName,
      crm_object_type: crmObjectType,
      crm_object_label: crmObjectLabel,
      searchable_crm_field_name: searchableCRMFieldName,
    }
  );

  return data;
};

export const updateTeamFieldConfigurationGroupLayout = async ({
  teamUuid,
  fieldConfigurationGroupLayouts,
}: {
  teamUuid: string;
  fieldConfigurationGroupLayouts: { uuid: string; position: number }[];
}): Promise<Team> => {
  const { data } = await axios.patch(
    `/api/teams/${teamUuid}/field_configuration_group_layouts/update_group_order`,
    { field_configuration_group_layout: fieldConfigurationGroupLayouts }
  );

  return data;
};

export const updateTeamFieldConfigurationLayout = async ({
  teamUuid,
  layoutGroupUuid,
  fieldConfigurationLayouts,
}: {
  teamUuid: string;
  layoutGroupUuid: string;
  fieldConfigurationLayouts: { uuid: string; position: number }[];
}): Promise<Team> => {
  const { data } = await axios.patch(
    `/api/teams/${teamUuid}/field_configuration_group_layouts/${layoutGroupUuid}/update_field_order`,
    { field_configuration_layout: fieldConfigurationLayouts }
  );

  return data;
};

export const createInvitations = async ({
  organizationUuid,
  teamUuid,
  emails,
  roles,
}: {
  organizationUuid: string;
  teamUuid: string;
  emails: string[];
  roles: UserRole[];
}) => {
  const { data } = await axios.post(`/api/invitations`, {
    invitation: {
      organization_uuid: organizationUuid,
      team_uuid: teamUuid,
      emails,
      roles,
    },
  });

  return data;
};

export const uploadMeetingRecording = async (
  formData: FormData
): Promise<{ message: string; meeting: Meeting }> => {
  const { data } = await axios.post<{ message: string; meeting: Meeting }>(
    "/api/meetings/upload",
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  return data;
};

export const setTeamsManagedByCRM = async (
  isCrmManaged: boolean
): Promise<void> => {
  await axios.patch(`/api/teams/set_team_management_type`, {
    is_crm_managed: isCrmManaged,
  });
};

export const updateOrganizationContext = async (
  context: string
): Promise<Organization> => {
  const { data } = await axios.patch(`/api/organizations/update_context`, {
    context,
  });

  return data;
};

export const updateTeamContext = async (
  teamUuid: string,
  context: string
): Promise<Organization> => {
  const { data } = await axios.patch(`/api/teams/${teamUuid}/update_context`, {
    context,
  });

  return data;
};

export const updateFieldConfigurationTeams = async (
  fieldConfigurationUuid: string,
  teamUuids: string[]
): Promise<FieldConfiguration> => {
  const { data } = await axios.patch(
    `/api/field_configurations/${fieldConfigurationUuid}/update_teams`,
    {
      team_uuids: teamUuids,
    }
  );

  return data;
};

export const createFieldConfiguration = async (
  fieldDefinitionUuid: string,
  fieldConfigUuidToCopy: string
): Promise<FieldConfiguration> => {
  const { data } = await axios.post(`/api/field_configurations`, {
    field_definition_uuid: fieldDefinitionUuid,
    field_configuration_uuid_to_copy: fieldConfigUuidToCopy,
  });

  return data;
};

export const deleteFieldConfiguration = async (
  fieldConfigurationUuid: string
): Promise<void> => {
  await axios.delete(`/api/field_configurations/${fieldConfigurationUuid}`);
};

export const revalidateFieldDefinition = async (
  fieldDefinitionUuid: string
): Promise<FieldDefinition> => {
  const { data } = await axios.patch(
    `/api/field_definitions/${fieldDefinitionUuid}/revalidate`
  );

  return data;
};

export const createIgnoredSyncedCRMObject = async ({
  meetingUuid,
  syncedCRMObjectUuid,
}: {
  meetingUuid: string;
  syncedCRMObjectUuid: string;
}): Promise<SyncedCRMObject> => {
  const { data } = await axios.post(
    `/api/meetings/${meetingUuid}/ignored_synced_crm_objects`,
    { synced_crm_object_uuid: syncedCRMObjectUuid }
  );

  return data;
};
export const deleteIgnoredSyncedCRMObject = async ({
  meetingUuid,
  syncedCRMObjectUuid,
}: {
  meetingUuid: string;
  syncedCRMObjectUuid: string;
}): Promise<boolean> => {
  const { status } = await axios.delete(
    `/api/meetings/${meetingUuid}/ignored_synced_crm_objects/${syncedCRMObjectUuid}`
  );

  return status === 204;
};

export const createFieldTestConfiguration = async (
  fieldConfigurationUuid: string,
  meetingUuid: string,
  testConfiguration: FieldTestOutcomeConfigurationDetail
): Promise<FieldTestOutcomeConfiguration> => {
  const { data } = await axios.post(
    `/api/field_configurations/${fieldConfigurationUuid}/field_test_outcome_configurations`,
    {
      meeting_uuid: meetingUuid,
      ...testConfiguration,
    }
  );

  return data;
};

export const updateFieldTestConfiguration = async (
  fieldConfigurationUuid: string,
  fieldTestConfigurationUuid: string,
  testConfiguration: FieldTestOutcomeConfigurationDetail
): Promise<FieldTestOutcomeConfiguration> => {
  const { data } = await axios.patch(
    `/api/field_configurations/${fieldConfigurationUuid}/field_test_outcome_configurations/${fieldTestConfigurationUuid}`,
    testConfiguration
  );

  return data;
};

export const deleteFieldTestConfiguration = async (
  fieldConfigurationUuid: string,
  fieldTestConfigurationUuid: string
): Promise<void> => {
  await axios.delete(
    `/api/field_configurations/${fieldConfigurationUuid}/field_test_outcome_configurations/${fieldTestConfigurationUuid}`
  );
};

export const updateSendSlackMessageAction = async (
  workflowUuid: string,
  actionableUuid: string,
  action: Partial<SendSlackMessageAction>
): Promise<SendSlackMessageAction> => {
  const { data } = await axios.patch(
    `/api/workflows/${workflowUuid}/workflow_actions/send_slack_message_actions/${actionableUuid}`,
    { workflow_action: action }
  );

  return data;
};

export const updateCreateSlackChannelAction = async (
  workflowUuid: string,
  actionableUuid: string,
  action: Partial<CreateSlackChannelAction>
): Promise<CreateSlackChannelAction> => {
  const { data } = await axios.patch(
    `/api/workflows/${workflowUuid}/workflow_actions/create_slack_channel_actions/${actionableUuid}`,
    { workflow_action: action }
  );

  return data;
};

export const updateSendTeamsMessageAction = async (
  workflowUuid: string,
  actionableUuid: string,
  action: Partial<SendTeamsMessageAction>
): Promise<SendTeamsMessageAction> => {
  const { data } = await axios.patch(
    `/api/workflows/${workflowUuid}/workflow_actions/send_teams_message_actions/${actionableUuid}`,
    { workflow_action: action }
  );

  return data;
};

export const updateUpdateCrmRecordAction = async (
  workflowUuid: string,
  actionableUuid: string,
  action: Partial<UpdateCrmRecordAction>
): Promise<UpdateCrmRecordAction> => {
  const { data } = await axios.patch(
    `/api/workflows/${workflowUuid}/workflow_actions/update_crm_record_actions/${actionableUuid}`,
    { workflow_action: action }
  );

  return data;
};

export const updateCreateCrmRecordAction = async (
  workflowUuid: string,
  actionableUuid: string,
  action: Partial<CreateCrmRecordAction>
): Promise<CreateCrmRecordAction> => {
  const { data } = await axios.patch(
    `/api/workflows/${workflowUuid}/workflow_actions/create_crm_record_actions/${actionableUuid}`,
    { workflow_action: action }
  );

  return data;
};

export const updateGenerateContentAction = async (
  workflowUuid: string,
  actionableUuid: string,
  action: Partial<GenerateContentAction>
): Promise<GenerateContentAction> => {
  const { data } = await axios.patch(
    `/api/workflows/${workflowUuid}/workflow_actions/generate_content_actions/${actionableUuid}`,
    { workflow_action: action }
  );

  return data;
};

export const updateWorkflowActionCrmField = async (
  workflowUuid: string,
  workflowStateUuid: string,
  workflowActionCrmFieldUuid: string,
  workflowActionCrmField: Partial<WorkflowActionCrmField>
): Promise<WorkflowActionCrmField> => {
  const { data } = await axios.patch(
    `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}/workflow_action_crm_fields/${workflowActionCrmFieldUuid}`,
    { workflow_action_crm_field: workflowActionCrmField }
  );

  return data;
};

export const createWorkflowActionCrmField = async (
  workflowUuid: string,
  workflowStateUuid: string,
  workflowActionCrmField: Partial<WorkflowActionCrmField>
): Promise<WorkflowActionCrmField> => {
  const { data } = await axios.post(
    `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}/workflow_action_crm_fields`,
    { workflow_action_crm_field: workflowActionCrmField }
  );

  return data;
};

export const deleteWorkflowActionCrmField = async (
  workflowUuid: string,
  workflowStateUuid: string,
  workflowActionCrmFieldUuid: string
): Promise<WorkflowActionCrmField> => {
  const { data } = await axios.delete(
    `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}/workflow_action_crm_fields/${workflowActionCrmFieldUuid}`
  );

  return data;
};

export const createWorkflowActionFieldConfiguration = async (
  workflowUuid: string,
  workflowStateUuid: string,
  workflowActionFieldConfiguration: Partial<WorkflowActionFieldConfiguration>
): Promise<WorkflowActionFieldConfiguration> => {
  const { data } = await axios.post(
    `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}/workflow_action_field_configurations`,
    { workflow_action_field_configuration: workflowActionFieldConfiguration }
  );

  return data;
};

export const updateWorkflowActionFieldConfiguration = async (
  workflowUuid: string,
  workflowStateUuid: string,
  workflowActionFieldConfigurationUuid: string,
  workflowActionFieldConfiguration: Partial<WorkflowActionFieldConfiguration>
): Promise<WorkflowActionFieldConfiguration> => {
  const { data } = await axios.patch(
    `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}/workflow_action_field_configurations/${workflowActionFieldConfigurationUuid}`,
    { workflow_action_field_configuration: workflowActionFieldConfiguration }
  );

  return data;
};

export const deleteWorkflowActionFieldConfiguration = async (
  workflowUuid: string,
  workflowStateUuid: string,
  workflowActionFieldConfigurationUuid: string
): Promise<WorkflowActionFieldConfiguration> => {
  const { data } = await axios.delete(
    `/api/workflows/${workflowUuid}/workflow_states/${workflowStateUuid}/workflow_action_field_configurations/${workflowActionFieldConfigurationUuid}`
  );

  return data;
};
