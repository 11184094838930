import React, { useCallback } from "react";
import { Col, Divider, Form, message, Row, Skeleton, Typography } from "antd";
import { CRMProvider } from "../../../../util/types";
import { CRMFieldType } from "../../../../util/enums";
import { DynamicVariableSelect } from "./shared/dynamic_variables/DynamicVariableSelect";
import { CRMObjectSelect } from "./shared/CRMObjectSelect";
import { useParams } from "react-router-dom";
import {
  replaceUndefinedValuesWithNull,
  updateCreateCrmRecordAction,
} from "../../../../util/api";
import { useCreateCrmRecordAction } from "../../../../util/data_hooks";
import { getFriendlyIntegrationLabel } from "../../../../helpers/label_maps";
import { WorkflowActionCrmFields } from "./shared/WorkflowActionCrmFields";
import { debounce } from "lodash";
import { useWorkflowStateContext } from "./shared/WorkflowStateContext";
import { DEBOUNCE_DELAY } from "../shared/WorkflowStateEditorDrawer";

enum CreateCRMRecordActionAttribute {
  CRM = "crm",
  CRMObjectType = "crm_object_type",
  CRMRecordId = "crm_record_id",
  AssociatedCRMObjectType = "associated_crm_object_type",
  AssociatedCRMRecordId = "associated_crm_record_id",
}

export interface CreateCrmRecordAction {
  [CreateCRMRecordActionAttribute.CRM]: CRMProvider;
  [CreateCRMRecordActionAttribute.CRMObjectType]: string;
  [CreateCRMRecordActionAttribute.CRMRecordId]: string;
  [CreateCRMRecordActionAttribute.AssociatedCRMObjectType]: string;
  [CreateCRMRecordActionAttribute.AssociatedCRMRecordId]: string;
}

export function CreateCRMRecord() {
  const [form] = Form.useForm<CreateCrmRecordAction>();
  const { workflowUuid } = useParams();

  const { workflowState, setIsSaving, afterSave } = useWorkflowStateContext();

  const crm = Form.useWatch(CreateCRMRecordActionAttribute.CRM, form);
  const crmObjectType = Form.useWatch(
    CreateCRMRecordActionAttribute.CRMObjectType,
    form
  );
  const associatedCRMObjectType = Form.useWatch(
    CreateCRMRecordActionAttribute.AssociatedCRMObjectType,
    form
  );

  const {
    action,
    mutate: mutateAction,
    isLoading: isActionLoading,
  } = useCreateCrmRecordAction(workflowUuid, workflowState.actionable_id);

  const debouncedHandleSubmit = useCallback(
    debounce(async () => {
      try {
        setIsSaving(true);
        await updateCreateCrmRecordAction(
          workflowUuid,
          workflowState.actionable_id,
          replaceUndefinedValuesWithNull(form.getFieldsValue())
        );

        await mutateAction();
        await afterSave();
      } catch (e) {
        message.error(e.message);
      }
      setIsSaving(false);
    }, DEBOUNCE_DELAY),
    [workflowUuid, workflowState.actionable_id, mutateAction, afterSave]
  );

  const crmLabel = getFriendlyIntegrationLabel(crm);

  if (isActionLoading) return <Skeleton active />;

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4} style={{ marginTop: 0 }}>
          Select Object Type
        </Typography.Title>
        <Typography.Text type="secondary">
          What type of record should be created?
        </Typography.Text>
      </div>

      <Form
        form={form}
        layout="vertical"
        initialValues={action}
        onValuesChange={debouncedHandleSubmit}
      >
        <Form.Item name={CreateCRMRecordActionAttribute.CRM} hidden />

        <Form.Item
          label="Object Type"
          name={CreateCRMRecordActionAttribute.CRMObjectType}
        >
          <CRMObjectSelect
            crm={form.getFieldValue(CreateCRMRecordActionAttribute.CRM)}
            value={form.getFieldValue(
              CreateCRMRecordActionAttribute.CRMObjectType
            )}
            onChange={(value) => {
              form.setFieldValue(
                CreateCRMRecordActionAttribute.CRMObjectType,
                value
              );
            }}
          />
        </Form.Item>

        <Divider />

        <div style={{ marginBottom: 15 }}>
          <Typography.Title level={4}>Associate Parent Record</Typography.Title>
          <Typography.Text type="secondary">
            This is the record in {crmLabel} that your new record will be
            associated with.
          </Typography.Text>
        </div>

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label="Associated Object Type"
              name={CreateCRMRecordActionAttribute.AssociatedCRMObjectType}
            >
              <CRMObjectSelect
                crm={form.getFieldValue(CreateCRMRecordActionAttribute.CRM)}
                value={form.getFieldValue(
                  CreateCRMRecordActionAttribute.AssociatedCRMObjectType
                )}
                onChange={(value) => {
                  form.setFieldsValue({
                    associated_crm_object_type: value,
                    associated_crm_record_id: null,
                  });
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Associated Record Id"
              name={CreateCRMRecordActionAttribute.AssociatedCRMRecordId}
              hidden={!associatedCRMObjectType}
            >
              <DynamicVariableSelect
                fieldType={CRMFieldType.Id}
                crmObjectType={associatedCRMObjectType}
                value={form.getFieldValue(
                  CreateCRMRecordActionAttribute.AssociatedCRMRecordId
                )}
                onChange={(value) =>
                  form.setFieldValue(
                    CreateCRMRecordActionAttribute.AssociatedCRMRecordId,
                    value
                  )
                }
              />
            </Form.Item>
          </Col>
        </Row>

        {crmObjectType && (
          <>
            <Divider />

            <WorkflowActionCrmFields
              title="Field Values"
              subTitle={`Which ${crmLabel} fields should be populated on the new record?`}
              crm={crm}
              crmObjectType={crmObjectType}
            />
          </>
        )}
      </Form>
    </>
  );
}
