import React, { ReactNode } from "react";
import {
  Button,
  Collapse,
  Dropdown,
  Flex,
  MenuProps,
  Timeline,
  Typography,
} from "antd";
import {
  SyncOutlined,
  FileAddOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { filter, isNull } from "lodash";
import { FieldConfigurationPlaygroundResponse } from "../../../../../util/types";
import { getContentPreview } from "../../../../../util/auto_generated_field_helpers";
import { useAppSelector } from "../../../../redux/hooks";
import { MarkdownWrapper } from "../../../../shared/MarkdownWrapper";
import { useFlags } from "launchdarkly-react-client-sdk";

interface Props {
  testResult: FieldConfigurationPlaygroundResponse;
  meetingUuidsInLibrary: string[];
  retest: () => void;
  pickDifferentMeeting: () => void;
  addToLibrary: () => void;
}

enum ExtraActions {
  ADD_TO_LIBRARY = "add_to_library",
}

const getScreeningStage = (
  result: FieldConfigurationPlaygroundResponse
): {
  color: string;
  text: ReactNode;
} => {
  if (result.screening_meta) {
    if (result.screening_meta.screening_passed) {
      return {
        color: "green",
        text: (
          <MarkdownWrapper>
            {result.generated_content.screening_context || "--"}
          </MarkdownWrapper>
        ),
      };
    }

    return {
      color: "red",
      text: (
        <MarkdownWrapper>
          {result.generated_content.screening_context || "--"}
        </MarkdownWrapper>
      ),
    };
  }

  return {
    color: "gray",
    text: "Screening omitted",
  };
};

export function ReviewResult({
  testResult,
  retest,
  pickDifferentMeeting,
  addToLibrary,
  meetingUuidsInLibrary,
}: Props) {
  const meetingToTest = useAppSelector(
    (state) => state.playground.meetingToTest
  );
  const fieldDefinition = useAppSelector(
    (state) => state.playground.fieldDefinition
  );
  const { testingLibrary: testingLibraryEnabledFlag } = useFlags();
  const summaryNode = (
    <Collapse
      expandIconPosition="start"
      items={[
        {
          key: "summary",
          label: <Typography.Text strong>Summary</Typography.Text>,
          children: (
            <MarkdownWrapper>{meetingToTest?.summary || "--"}</MarkdownWrapper>
          ),
        },
      ]}
    />
  );

  const screeningStage = getScreeningStage(testResult);
  const screeningNode = (
    <Collapse
      expandIconPosition="start"
      defaultActiveKey={"screening"}
      items={[
        {
          key: "screening",
          label: <Typography.Text strong>Screening</Typography.Text>,
          children: <>{screeningStage.text}</>,
        },
      ]}
    />
  );

  const textGenerationNode = testResult.meta ? (
    <Collapse
      expandIconPosition="start"
      defaultActiveKey={"text"}
      items={[
        {
          key: "text",
          label: <Typography.Text strong>Generated Text</Typography.Text>,
          children: (
            <MarkdownWrapper>
              {testResult.generated_content.context || "--"}
            </MarkdownWrapper>
          ),
        },
      ]}
    />
  ) : null;

  const contentNode = (
    <Collapse
      expandIconPosition="start"
      defaultActiveKey={"insight"}
      items={[
        {
          key: "insight",
          label: <Typography.Text strong>Generated Insight</Typography.Text>,
          children: (
            <MarkdownWrapper>
              {getContentPreview(
                testResult.generated_content.content,
                fieldDefinition.field_type,
                fieldDefinition.picklist_definitions
              ) || "--"}
            </MarkdownWrapper>
          ),
        },
      ]}
    />
  );

  const events = [
    {
      children: summaryNode,
    },
    {
      children: screeningNode,
      color: screeningStage.color,
    },
    {
      children: textGenerationNode,
    },
    {
      children: contentNode,
    },
  ];

  const extraActionsMenuItems: MenuProps["items"] = [
    {
      key: ExtraActions.ADD_TO_LIBRARY,
      label: meetingUuidsInLibrary.includes(meetingToTest?.meeting?.uuid || "")
        ? "Edit library entry"
        : "Add to library",
      icon: <MenuUnfoldOutlined />,
    },
  ];

  const executeAction: MenuProps["onClick"] = (e) => {
    if (e.key === ExtraActions.ADD_TO_LIBRARY) {
      addToLibrary();
    }
  };

  return (
    <div>
      <Flex style={{ marginBottom: "2rem" }} gap={"small"}>
        <Button type="primary" onClick={retest} icon={<SyncOutlined />}>
          Retest same meeting
        </Button>
        {testingLibraryEnabledFlag && (
          <Dropdown.Button
            style={{ marginRight: "0.5rem" }}
            onClick={pickDifferentMeeting}
            menu={{ items: extraActionsMenuItems, onClick: executeAction }}
          >
            <FileAddOutlined /> Select different meeting
          </Dropdown.Button>
        )}
        {!testingLibraryEnabledFlag && (
          <Button
            style={{ marginRight: "0.5rem" }}
            onClick={pickDifferentMeeting}
          >
            <FileAddOutlined /> Select different meeting
          </Button>
        )}
      </Flex>
      <Timeline items={filter(events, (event) => !isNull(event.children))} />
    </div>
  );
}
