import { Integration } from "./enums";

export const MEETING_PROVIDERS = [
  Integration.Zoom,
  Integration.ZoomAdmin,
  Integration.Gong,
  Integration.Avoma,
  Integration.Microsoft,
  Integration.Google,
  Integration.Salesloft,
  Integration.Chorus,
] as const;

export const NOTIFICATION_PROVIDERS = [
  Integration.Slack,
  Integration.Microsoft,
] as const;

export const CRM_PROVIDERS = [
  Integration.Salesforce,
  Integration.Hubspot,
] as const;
