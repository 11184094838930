import React from "react";
import { Integration } from "../../util/enums";

import salesforce from "../../../assets/images/salesforce.png";
import hubspot from "../../../assets/images/hubspot.png";
import slack from "../../../assets/images/slack.png";
import zoom from "../../../assets/images/zoom.png";
import gong from "../../../assets/images/gong.png";
import avoma from "../../../assets/images/avoma.png";
import teams from "../../../assets/images/microsoft-teams.png";
import salesloft from "../../../assets/images/salesloft_transparent.png";
import chorus from "../../../assets/images/chorus.png";
import google from "../../../assets/images/google.png";

const imageMap = {
  [Integration.Salesforce]: salesforce,
  [Integration.Hubspot]: hubspot,
  [Integration.Zoom]: zoom,
  [Integration.ZoomAdmin]: zoom,
  [Integration.Slack]: slack,
  [Integration.Gong]: gong,
  [Integration.Avoma]: avoma,
  [Integration.Chorus]: chorus,
  [Integration.Microsoft]: teams,
  [Integration.Google]: google,
  [Integration.Salesloft]: salesloft,
};

export function IntegrationLogo({
  type,
  width = 40,
}: {
  type: Integration;
  width?: number;
}) {
  return <img src={imageMap[type]} sizes="small" style={{ width }} />;
}
