import { message, Modal, Select, Table } from "antd";
import React from "react";
import { useIntegrationUsers } from "../../../util/data_hooks";
import { Integration } from "../../../util/enums";
import { getFriendlyIntegrationLabel } from "../../../helpers/label_maps";
import { updateUser } from "../../../util/api";

interface IntegrationUserAssociationModalProps {
  open: boolean;
  integration: Integration;
  setOpen: (open: boolean) => void;
}

export function IntegrationUserAssociationModal({
  open,
  integration,
  setOpen,
}: IntegrationUserAssociationModalProps) {
  const { response } = useIntegrationUsers(integration);

  const columns = [
    {
      title: "Swyft User",
      dataIndex: "user_email",
    },
    {
      title: `Associated ${getFriendlyIntegrationLabel(integration)} User`,
      render: ({ user_uuid, integration_user_id }) => {
        return (
          <Select
            style={{ minWidth: 250 }}
            popupMatchSelectWidth
            defaultValue={integration_user_id}
            showSearch
            optionFilterProp="label"
            options={response.integration_users.map(({ id, name, email }) => {
              return {
                label: `${name} (${email})`,
                value: id,
              };
            })}
            onChange={async (value) => {
              let body;

              try {
                switch (integration) {
                  case Integration.Gong:
                    body = { gong_user_id: value };
                    break;
                  case Integration.ZoomAdmin:
                    body = { zoom_user_id: value };
                    break;
                  case Integration.Slack:
                    body = { slack_user_id: value };
                    break;
                  case Integration.Microsoft:
                    body = { microsoft_user_id: value };
                    break;
                }

                if (body) {
                  await updateUser(user_uuid, body);
                  message.success("User updated");
                }
              } catch {
                message.error("An error occurred. Please try again later.");
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <Modal
      title={`${getFriendlyIntegrationLabel(integration)} Users`}
      open={open}
      onCancel={() => setOpen(false)}
      width="75rem"
      footer={null}
    >
      <Table
        rowKey={({ user_uuid }) => user_uuid}
        dataSource={response.merged_users}
        columns={columns}
        pagination={false}
      />
    </Modal>
  );
}
