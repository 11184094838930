import React from "react";
import { Typography } from "antd";
import { DiffInformation } from "../../../util/auto_generated_field_helpers";
import { CRMFieldType } from "../../../util/enums";
import { MarkdownWrapper } from "../../shared/MarkdownWrapper";

export function DiffText({
  diff,
}: {
  diff: DiffInformation[];
  crmFieldType: CRMFieldType;
}) {
  return diff.map(({ value, type }, index) => {
    let bg = null;

    switch (type) {
      case "added":
        bg = "#E6FFEC";
        break;
      case "removed":
        bg = "#FFE9E9";
        break;
    }

    return (
      <Typography.Text
        key={index + value}
        style={{ backgroundColor: bg, whiteSpace: "pre-wrap" }}
      >
        {value}
      </Typography.Text>
    );
  });
}

export function DiffTextMarkdown({ diff }: { diff: DiffInformation[] }) {
  // Create HTML string with colored backgrounds for diffs
  const content = diff.reduce((acc, { value, type }) => {
    switch (type) {
      case "added":
        return acc + `<span style="background-color: #E6FFEC">${value}</span>`;
      case "removed":
        return acc + `<span style="background-color: #FFE9E9">${value}</span>`;
      default:
        return acc + value;
    }
  }, "");

  return <MarkdownWrapper>{content}</MarkdownWrapper>;
}
