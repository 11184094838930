import React from "react";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";

export function MarkdownWrapper({ children }: { children: any }) {
  return (
    <Markdown
      rehypePlugins={[rehypeRaw]}
      components={{
        p: ({ ...rest }) => {
          return (
            <p
              style={{ fontSize: 14, marginTop: 0, lineHeight: 1.5 }}
              {...rest}
            />
          );
        },
        li: ({ ...rest }) => (
          <li
            style={{
              fontSize: 14,
              lineHeight: 1.5,
              paddingBottom: 5,
            }}
            {...rest}
          />
        ),
        ul: ({ ...rest }) => (
          <ul style={{ paddingLeft: 10, marginTop: 0 }} {...rest} />
        ),
        ol: ({ ...rest }) => (
          <ul style={{ paddingLeft: 10, marginTop: 0 }} {...rest} />
        ),
      }}
    >
      {children}
    </Markdown>
  );
}
