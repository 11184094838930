import { isNil } from "lodash";
import { CRMFieldType } from "../../../../../../util/enums";
import {
  FieldConfiguration,
  FieldDefinition,
  FieldTestOutcomeConfigurationDetail,
} from "../../../../../../util/types";

export interface TestLibraryItemRequirements {
  screeningNeeded: boolean;
  contentNeeded: boolean;
  contentReasoningNeeded: boolean;
}

export function getTestLibraryItemRequirements(
  fieldDefinition: FieldDefinition,
  fieldConfiguration: FieldConfiguration,
  testConfiguration: FieldTestOutcomeConfigurationDetail
): TestLibraryItemRequirements {
  const screeningNeeded = fieldConfiguration.screening_enabled;
  const contentNeeded =
    !screeningNeeded || testConfiguration.screening_expected_outcome;
  return {
    screeningNeeded: screeningNeeded,
    contentNeeded: contentNeeded,
    contentReasoningNeeded:
      contentNeeded &&
      ![CRMFieldType.Textarea, CRMFieldType.HTML, CRMFieldType.String].includes(
        fieldDefinition.field_type
      ),
  };
}

export function configHasErrors(
  fieldDefinition: FieldDefinition,
  fieldConfiguration: FieldConfiguration,
  testConfiguration: FieldTestOutcomeConfigurationDetail
) {
  const { screeningNeeded, contentNeeded, contentReasoningNeeded } =
    getTestLibraryItemRequirements(
      fieldDefinition,
      fieldConfiguration,
      testConfiguration
    );

  return (
    (screeningNeeded &&
      (isNil(testConfiguration.screening_expected_outcome) ||
        isNil(testConfiguration.screening_expected_reasoning))) ||
    (contentNeeded && isNil(testConfiguration.content_expected_outcome)) ||
    (contentReasoningNeeded &&
      isNil(testConfiguration.content_expected_reasoning))
  );
}
