import { isNil } from "lodash";
import { CRMFieldType } from "./enums";
import { DATE_FORMAT, DATETIME_FORMAT, TIME_FORMAT } from "./parseCRMValue";
import {
  AutoGeneratedField,
  CRMField,
  FieldDefinition,
  PicklistDefinition,
} from "./types";
import { diffSentences, diffWordsWithSpace } from "diff";
import moment from "moment";

const decodeHtmlEntities = (string: string) => {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = string;
  return textarea.value;
};

export const getContentPreview = (
  value: any,
  type: CRMFieldType,
  picklistOptions: Pick<PicklistDefinition, "label" | "value">[] = []
): string => {
  switch (type) {
    case CRMFieldType.Boolean:
      if (value === null || value === undefined) return "";
      return value ? "Yes" : "No";
    case CRMFieldType.Multipicklist:
      if (value === null || value.length === 0) return "";

      // Find corresponding labels for each value
      return value
        .sort()
        .map((val: string) => {
          const label = picklistOptions.find(
            (picklistOption) => picklistOption.value === val
          )?.label;

          return label || val;
        })
        .join(", ");
    case CRMFieldType.Picklist: {
      const label = picklistOptions.find(
        (picklistOption) => picklistOption.value === value
      )?.label;

      return label || value || "";
    }
    case CRMFieldType.Date:
      if (value === null) return "";
      if (moment.isMoment(value)) {
        return value.format("MM/DD/YY");
      } else {
        return moment(value).format("MM/DD/YY");
      }
    case CRMFieldType.Time:
      if (value === null) return "";
      if (moment.isMoment(value)) {
        return value.format("h:mm A");
      } else {
        return moment(value).format("h:mm A");
      }
    case CRMFieldType.DateTime:
      if (value === null) return "";
      if (moment.isMoment(value)) {
        return value.format("dddd, MM/DD/YY, h:mm A");
      } else {
        return moment(value).format("dddd, MM/DD/YY, h:mm A");
      }
    case CRMFieldType.HTML:
      if (value === null) return "";

      return decodeHtmlEntities(
        value
          .replace(/<br\s*\/?>/gi, "\n")
          .replace(/<\/p>/gi, "\n")
          .replace(/<p[^>]*>/gi, "\n")
          .replace(/<\/?[^>]+(>|$)/g, "")
      );
    case CRMFieldType.Currency:
    case CRMFieldType.Double:
    case CRMFieldType.Percent:
      return value === null ? "" : value.toString();
    default:
      return value ? value.toString() : "";
  }
};

export const convertContentToJson = (type: CRMFieldType, value: any) => {
  switch (type) {
    case CRMFieldType.String:
    case CRMFieldType.EncryptedString:
    case CRMFieldType.Textarea:
    case CRMFieldType.HTML:
    case CRMFieldType.Double:
    case CRMFieldType.Currency:
    case CRMFieldType.Percent:
      return value;
    default:
      return JSON.stringify(value);
  }
};

export const convertJsonContentToValue = (
  type: CRMFieldType,
  value: string
) => {
  let parsedValue;

  switch (type) {
    case CRMFieldType.Date:
      parsedValue = value ? moment(value, DATE_FORMAT) : null;
      break;
    case CRMFieldType.DateTime:
      parsedValue = value ? moment(value, DATETIME_FORMAT) : null;
      break;
    case CRMFieldType.Time:
      parsedValue = value ? moment(value, TIME_FORMAT).utc() : null;
      break;
    case CRMFieldType.Multipicklist:
    case CRMFieldType.Picklist:
      parsedValue = JSON.parse(value);
      break;
    default:
      parsedValue = value;
  }

  return parsedValue;
};

export interface DiffInformation {
  value: string;
  type: "equal" | "added" | "removed";
}

export const getAutoGeneratedFieldDiffs = (
  autoGeneratedField: AutoGeneratedField,
  fieldDefinition: FieldDefinition,
  crmField: CRMField
) => {
  const crmContent = getContentPreview(
    crmField.crm_field_value,
    crmField.crm_field_type,
    crmField.crm_field_picklist_values
  );

  const autoGeneratedContent = getContentPreview(
    autoGeneratedField.content,
    fieldDefinition.field_type,
    fieldDefinition.picklist_definitions
  );

  const shouldDiffSentences =
    crmContent.length > 500 || autoGeneratedContent.length > 500;

  const diffArray = shouldDiffSentences
    ? diffSentences(crmContent, autoGeneratedContent)
    : diffWordsWithSpace(crmContent, autoGeneratedContent);

  const leftDiff: DiffInformation[] = [];
  const rightDiff: DiffInformation[] = [];

  diffArray.forEach(({ added, removed, value }) => {
    const type = removed ? "removed" : added ? "added" : "equal";

    if (removed) leftDiff.push({ value, type });
    if (added) rightDiff.push({ value, type });

    if (!removed && !added && value) {
      rightDiff.push({ value, type });
      leftDiff.push({ value, type });
    }
  });

  return { leftDiff, rightDiff };
};

export const getFieldDiffs = (
  leftItem: Pick<
    CRMField,
    "crm_field_value" | "crm_field_type" | "crm_field_picklist_values"
  >,
  rightItem: Pick<
    CRMField,
    "crm_field_value" | "crm_field_type" | "crm_field_picklist_values"
  >,
  sentenceDiffOverride?: boolean
) => {
  const leftContent = getContentPreview(
    leftItem.crm_field_value,
    leftItem.crm_field_type,
    leftItem.crm_field_picklist_values
  );

  const rightContent = getContentPreview(
    rightItem.crm_field_value,
    rightItem.crm_field_type,
    rightItem.crm_field_picklist_values
  );

  const shouldDiffSentences = isNil(sentenceDiffOverride)
    ? leftContent.length > 500 || rightContent.length > 500
    : sentenceDiffOverride;

  const diffArray = shouldDiffSentences
    ? diffSentences(leftContent, rightContent)
    : diffWordsWithSpace(leftContent, rightContent);

  const leftDiff: DiffInformation[] = [];
  const rightDiff: DiffInformation[] = [];

  diffArray.forEach(({ added, removed, value }) => {
    const type = removed ? "removed" : added ? "added" : "equal";

    if (removed) leftDiff.push({ value, type });
    if (added) rightDiff.push({ value, type });

    if (!removed && !added && value) {
      rightDiff.push({ value, type });
      leftDiff.push({ value, type });
    }
  });

  return { leftDiff, rightDiff };
};

export const isFieldTypeAutomated = (fieldType: CRMFieldType): boolean => {
  return [
    CRMFieldType.String,
    CRMFieldType.Textarea,
    CRMFieldType.HTML,
    CRMFieldType.Currency,
    CRMFieldType.Double,
    CRMFieldType.Percent,
    CRMFieldType.Boolean,
    CRMFieldType.Picklist,
    CRMFieldType.Multipicklist,
    CRMFieldType.Date,
    CRMFieldType.DateTime,
  ].includes(fieldType);
};
