import React, { useState } from "react";
import { notification, Steps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { MeetingSelect } from "../MeetingSelect";
import { ReviewResult } from "../ReviewResult";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { FieldConfigurationPlaygroundResponse } from "../../../../../../util/types";
import {
  createFieldConfigurationPlaygroundTest,
  pollFieldConfigurationPlaygroundTest,
} from "../../../../../../util/api";
import { get } from "lodash";
import {
  setMeetingToTest,
  TestMeeting,
} from "../../../../../redux/features/PlaygroundFieldTesting/playgroundSlice";
import { TestOutcomeConfigCreateModal } from "../test_library/TestOutcomeConfigCreateModal";
import { convertContentToJson } from "../../../../../../util/auto_generated_field_helpers";
import { fieldTestOutcomeConfigurations } from "../../../../../../util/data_hooks";

enum TestingSteps {
  MEETING_SELECT = 0,
  ANALYZING = 1,
  REVIEW = 2,
}

export function SingleTest() {
  const dispatch = useAppDispatch();
  const fieldToTest = useAppSelector((state) => state.playground.fieldToTest);
  const fieldDefinition = useAppSelector(
    (state) => state.playground.fieldDefinition
  );
  const meetingToTest = useAppSelector(
    (state) => state.playground.meetingToTest
  );
  const [notificationApi, contextHolder] = notification.useNotification();
  const [currentStep, setCurrentStep] = useState<TestingSteps>(
    TestingSteps.MEETING_SELECT
  );
  const [testResult, setTestResult] =
    useState<FieldConfigurationPlaygroundResponse>();
  const [createTestInLibraryModalOpen, setCreateTestInLibraryModalOpen] =
    useState(false);
  const { fieldTestOutcomeConfigurations: testLibraryConfigs } =
    fieldTestOutcomeConfigurations(fieldToTest);

  const testMeeting = async (selectedMeeting: TestMeeting): Promise<void> => {
    setCurrentStep(TestingSteps.ANALYZING);

    try {
      dispatch(setMeetingToTest(selectedMeeting));

      const jobInfo = await createFieldConfigurationPlaygroundTest(
        selectedMeeting.meeting.uuid,
        fieldToTest
      );

      const testResults = await pollFieldConfigurationPlaygroundTest(
        jobInfo.job_id
      );

      setTestResult(testResults);

      setCurrentStep(TestingSteps.REVIEW);
    } catch (err) {
      if (get(err, "response.status", 500) < 500) {
        showError(
          get(
            err,
            "response.data.error",
            "Ooops, something is wrong on our end! Please try again later."
          )
        );
      } else {
        console.log(err);
        showError(
          "Ooops, something is wrong on our end! Please try again later."
        );
      }
      setCurrentStep(TestingSteps.MEETING_SELECT);
    }
  };

  const showError = (message: string) => {
    notificationApi["error"]({
      message: "Could not complete test",
      description: message,
      duration: 0,
    });
  };

  const meetingUuidsInLibrary = testLibraryConfigs.map(
    (testConfig) => testConfig.meeting_uuid
  );

  return (
    <>
      {contextHolder}
      <Steps
        size="small"
        labelPlacement="vertical"
        current={currentStep}
        onChange={setCurrentStep}
        items={[
          { title: "Select Meeting" },
          {
            title: "Analyzing",
            icon: currentStep === 1 ? <LoadingOutlined /> : false,
            disabled: true,
          },
          {
            title: "Review Results",
            disabled: true,
          },
        ]}
      />

      {currentStep === 0 && (
        <div style={{ marginTop: "1rem" }}>
          <MeetingSelect testMeeting={testMeeting} />
        </div>
      )}

      {currentStep === 2 && (
        <div style={{ marginTop: "1rem" }}>
          <ReviewResult
            testResult={testResult}
            retest={() => testMeeting(meetingToTest)}
            pickDifferentMeeting={() =>
              setCurrentStep(TestingSteps.MEETING_SELECT)
            }
            addToLibrary={() => setCreateTestInLibraryModalOpen(true)}
            meetingUuidsInLibrary={meetingUuidsInLibrary}
          />
        </div>
      )}

      <TestOutcomeConfigCreateModal
        open={createTestInLibraryModalOpen}
        setOpen={setCreateTestInLibraryModalOpen}
        initialTestConfiguration={{
          screening_expected_outcome:
            testResult?.screening_meta?.screening_passed,
          screening_expected_reasoning:
            testResult?.generated_content?.screening_context,
          content_expected_outcome: convertContentToJson(
            fieldDefinition?.field_type,
            testResult?.generated_content?.auto_generated_content
          ),
          content_expected_reasoning: testResult?.meta?.text_generated,
        }}
        shouldEdit={meetingUuidsInLibrary.includes(
          meetingToTest?.meeting?.uuid || ""
        )}
        fieldTestOutcomeConfiguration={testLibraryConfigs.find(
          (testConfig) =>
            testConfig.meeting_uuid === meetingToTest?.meeting?.uuid
        )}
      />
    </>
  );
}
