import React, { useState } from "react";
import { useWorkflows } from "../../../util/data_hooks";
import { Table, Typography, Skeleton, Row, Button } from "antd";
import { Workflow } from "../../../util/types";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { getFriendlyWorkflowTriggerLabel } from "../../../helpers/label_maps";
import { WorkflowDetailsEditorModal } from "./shared/WorkflowDetailsEditorModal";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useAppSelector } from "../../redux/hooks";
import { green } from "@ant-design/colors";
import { usePageTitle } from "../../hooks/usePageTitle";
import { TeamSelectBanner } from "../shared/TeamSelectBanner";
import { PlusCircleOutlined } from "@ant-design/icons";
import { WorkflowTriggerType } from "../../../util/enums";

export function Workflows() {
  usePageTitle("Workflows");

  const navigate = useNavigate();
  const selectedTeam = useAppSelector((state) => state.team.selectedTeam);
  const teamSelectLoading = useAppSelector((state) => state.team.isLoading);

  const { workflows, isLoading: isWorkflowsLoading } =
    useWorkflows(selectedTeam);

  const [workflowDetailsEditorModalOpen, setWorkflowDetailsEditorModalOpen] =
    useState<boolean>(false);

  const isLoading = isWorkflowsLoading || teamSelectLoading;

  const columns: ColumnsType<Workflow> = [
    {
      title: "Enabled",
      dataIndex: "enabled",
      width: 40,
      align: "center",
      render: (enabled) =>
        enabled ? <CheckCircleTwoTone twoToneColor={green.primary} /> : "-",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Trigger",
      dataIndex: "triggerable_type",
      render: (workflowTrigger: WorkflowTriggerType) =>
        getFriendlyWorkflowTriggerLabel(workflowTrigger),
    },
  ];

  if (isLoading) return <Skeleton active />;

  return (
    <>
      <WorkflowDetailsEditorModal
        team={selectedTeam}
        open={workflowDetailsEditorModalOpen}
        setOpen={setWorkflowDetailsEditorModalOpen}
      />

      <TeamSelectBanner />

      <Row justify="space-between">
        <Typography.Title level={3} style={{ marginTop: 0 }}>
          Workflows
        </Typography.Title>

        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          onClick={() => setWorkflowDetailsEditorModalOpen(true)}
        >
          Create New Workflow
        </Button>
      </Row>

      <Table<Workflow>
        rowKey={({ uuid }) => uuid}
        onRow={({ uuid, enabled }) => {
          return {
            style: {
              cursor: "pointer",
              ...(!enabled && {
                background: "#f0f0f0",
                "&:hover": {
                  background: "#f0f0f0",
                },
              }),
            },
            onClick: () => navigate(`${uuid}`),
          };
        }}
        loading={isLoading}
        dataSource={workflows?.sort(
          (a, b) => Number(b.enabled) - Number(a.enabled)
        )}
        columns={columns}
        pagination={false}
      />
    </>
  );
}
