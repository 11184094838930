import React from "react";
import {
  FieldConfiguration,
  FieldDefinition,
  FieldTestOutcomeConfigurationWithMeeting,
} from "../../../../../../util/types";
import { Steps, StepsProps } from "antd";
import { getTestLibraryItemRequirements } from "./libraryHelper";

interface Props {
  fieldDefinition: FieldDefinition;
  fieldConfiguration: FieldConfiguration;
  testConfig: FieldTestOutcomeConfigurationWithMeeting;
  onClick: () => void;
}

export function TestPreview({
  fieldDefinition,
  fieldConfiguration,
  testConfig,
  onClick,
}: Props) {
  const { screeningNeeded, contentNeeded, contentReasoningNeeded } =
    getTestLibraryItemRequirements(
      fieldDefinition,
      fieldConfiguration,
      testConfig
    );
  const screeningOutcome = testConfig.screening_expected_outcome;

  const items: StepsProps["items"] = [];

  if (screeningNeeded) {
    if (testConfig.screening_expected_reasoning) {
      items.push({
        title: "Screen Reasoning",
        description: "Evaluate reasoning result of the screening process",
        status: "finish",
      });
    }

    items.push({
      title: "Screening",
      description: screeningOutcome
        ? "Screening should pass"
        : "Screening should fail",
      status: screeningOutcome ? "process" : "error",
    });
  }

  if (contentNeeded) {
    if (contentReasoningNeeded) {
      items.push({
        title: "Content Reasoning",
        description: "Evaluate reasoning result of the content",
        status: "finish",
      });
    }

    items.push({
      title: "Content",
      description: "Evaluate the content",
      status: "process",
    });
  }

  return (
    <div onClick={onClick}>
      <Steps
        style={{
          marginTop: 8,
        }}
        type="inline"
        items={items}
        onChange={onClick}
      />
    </div>
  );
}
