import { Card, Space, Tooltip, Typography, message } from "antd";
import React, { useState } from "react";
import { Connection, Handle, Position } from "reactflow";
import { WorkflowState } from "../../../util/types";
import { WorkflowActionType } from "../../../util/enums";
import { fetchCreateWorkflowTransition } from "../../../util/api";
import { useNavigate, useParams } from "react-router-dom";
import { useWorkflow } from "../../../util/data_hooks";
import { WorkflowActionTypeIcon } from "../../shared/WorkflowActionTypeIcon";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { gold } from "@ant-design/colors";
import { WorkflowActionModal } from "./shared/WorkflowActionModal";

interface WorkflowStateNodeProps {
  data: { workflowState: WorkflowState };
}

export function WorkflowStateNode(props: WorkflowStateNodeProps) {
  const {
    data: { workflowState },
  } = props;

  const navigate = useNavigate();

  const { workflowUuid } = useParams();
  const { mutate: mutateWorkflow } = useWorkflow(workflowUuid);

  const [newActionModalOpen, setNewActionModalOpen] = useState<boolean>(false);

  const handleConnect = async (connection: Connection) => {
    try {
      await fetchCreateWorkflowTransition({
        workflow_state_uuid: connection.source,
        target_workflow_state_uuid: connection.target,
      });
    } catch (e) {
      message.error("Error connecting actions. Please try again");
    }

    mutateWorkflow();
  };

  const isTrigger =
    workflowState.actionable_type === WorkflowActionType.Trigger;

  return (
    <>
      <WorkflowActionModal
        open={newActionModalOpen}
        workflowState={workflowState}
        setOpen={setNewActionModalOpen}
      />

      {!isTrigger && (
        <Handle
          type="target"
          position={Position.Top}
          onConnect={handleConnect}
          isConnectable={!workflowState.source_workflow_transition}
        />
      )}

      <Card
        hoverable={!isTrigger}
        onClick={() => {
          if (isTrigger) return;
          navigate(`workflow-states/${workflowState.uuid}`);
        }}
        size="small"
        style={{ width: 300, textAlign: "center" }}
        actions={[
          <Typography.Link
            key="new-action"
            onClick={(e) => {
              e.stopPropagation();
              setNewActionModalOpen(true);
            }}
          >
            New Action
          </Typography.Link>,
          <Typography.Link
            key="new-condition"
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `workflow-states/${workflowState.uuid}/workflow-transitions/new`
              );
            }}
          >
            New Condition
          </Typography.Link>,
        ]}
      >
        <Space style={{ lineHeight: 0 }}>
          <WorkflowActionTypeIcon
            actionType={workflowState.actionable_type}
            size="lg"
          />

          <Typography.Text strong>{workflowState.name}</Typography.Text>

          {workflowState.missing_required_fields && (
            <Tooltip title="Missing Required Fields">
              <ExclamationCircleTwoTone
                style={{ fontSize: 18 }}
                twoToneColor={gold[5]}
              />
            </Tooltip>
          )}
        </Space>
      </Card>

      <Handle
        type="source"
        position={Position.Bottom}
        onConnect={handleConnect}
      />
    </>
  );
}
