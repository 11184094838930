import React, { useCallback } from "react";
import { Col, Divider, Form, message, Row, Skeleton, Typography } from "antd";
import { CRMProvider } from "../../../../util/types";
import { CRMObjectSelect } from "./shared/CRMObjectSelect";
import { CRMFieldType } from "../../../../util/enums";
import { getFriendlyIntegrationLabel } from "../../../../helpers/label_maps";
import { DynamicVariableSelect } from "./shared/dynamic_variables/DynamicVariableSelect";
import { useUpdateCrmRecordAction } from "../../../../util/data_hooks";
import { useParams } from "react-router-dom";
import {
  replaceUndefinedValuesWithNull,
  updateUpdateCrmRecordAction,
} from "../../../../util/api";
import { WorkflowActionCrmFields } from "./shared/WorkflowActionCrmFields";
import debounce from "lodash/debounce";
import { useWorkflowStateContext } from "./shared/WorkflowStateContext";
import { DEBOUNCE_DELAY } from "../shared/WorkflowStateEditorDrawer";

enum UpdateCRMRecordActionAttribute {
  CRM = "crm",
  CRMObjectType = "crm_object_type",
  CRMRecordId = "crm_record_id",
}

export interface UpdateCrmRecordAction {
  [UpdateCRMRecordActionAttribute.CRM]: CRMProvider;
  [UpdateCRMRecordActionAttribute.CRMObjectType]: string;
  [UpdateCRMRecordActionAttribute.CRMRecordId]: string;
}

export function UpdateCRMRecord() {
  const [form] = Form.useForm<UpdateCrmRecordAction>();
  const { workflowUuid } = useParams();

  const { workflowState, afterSave, setIsSaving } = useWorkflowStateContext();

  const crm = Form.useWatch(UpdateCRMRecordActionAttribute.CRM, form);
  const crmObjectType = Form.useWatch(
    UpdateCRMRecordActionAttribute.CRMObjectType,
    form
  );

  const {
    action,
    mutate: mutateAction,
    isLoading: isActionLoading,
  } = useUpdateCrmRecordAction(workflowUuid, workflowState.actionable_id);

  const debouncedHandleSubmit = useCallback(
    debounce(async () => {
      try {
        setIsSaving(true);

        await updateUpdateCrmRecordAction(
          workflowUuid,
          workflowState.actionable_id,
          replaceUndefinedValuesWithNull(form.getFieldsValue())
        );
        await mutateAction();
        await afterSave();
      } catch (e) {
        message.error("An error occurred. Please try again.");
      }
      setIsSaving(false);
    }, DEBOUNCE_DELAY),
    [workflowUuid, workflowState.actionable_id, mutateAction, afterSave]
  );

  const crmLabel = getFriendlyIntegrationLabel(crm);

  if (isActionLoading) return <Skeleton active />;

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4} style={{ marginTop: 0 }}>
          Select {crmLabel} Object
        </Typography.Title>

        <Typography.Text type="secondary">
          Which record in {crmLabel} should be updated?
        </Typography.Text>
      </div>

      <Form
        form={form}
        layout="vertical"
        initialValues={action}
        onValuesChange={debouncedHandleSubmit}
      >
        <Form.Item name={UpdateCRMRecordActionAttribute.CRM} hidden />

        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label="Object Type"
              name={UpdateCRMRecordActionAttribute.CRMObjectType}
            >
              <CRMObjectSelect
                crm={form.getFieldValue(UpdateCRMRecordActionAttribute.CRM)}
                value={form.getFieldValue(
                  UpdateCRMRecordActionAttribute.CRMObjectType
                )}
                onChange={(value) => {
                  form.setFieldsValue({
                    crm_object_type: value,
                    crm_record_id: null,
                  });
                }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Record Id"
              name={UpdateCRMRecordActionAttribute.CRMRecordId}
              hidden={!crmObjectType}
            >
              <DynamicVariableSelect
                fieldType={CRMFieldType.Id}
                crmObjectType={crmObjectType}
                value={form.getFieldValue(
                  UpdateCRMRecordActionAttribute.CRMRecordId
                )}
                onChange={(value) =>
                  form.setFieldValue(
                    UpdateCRMRecordActionAttribute.CRMRecordId,
                    value
                  )
                }
              />
            </Form.Item>
          </Col>
        </Row>

        {crmObjectType && (
          <>
            <Divider />

            <WorkflowActionCrmFields
              title="Fields to Update"
              subTitle={`Which ${crmLabel} fields should be updated?`}
              crm={crm}
              crmObjectType={crmObjectType}
            />
          </>
        )}
      </Form>
    </>
  );
}
