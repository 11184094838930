import React from "react";
import { Collapse, Drawer, Timeline, Typography, theme } from "antd";
import {
  FieldConfigurationPlaygroundResponse,
  FieldDefinition,
  FieldTestOutcomeConfigurationWithMeeting,
  PicklistDefinition,
} from "../../../../../../util/types";
import { filter, get } from "lodash";
import { isNull } from "lodash";
import { convertJsonContentToValue } from "../../../../../../util/auto_generated_field_helpers";
import { ResultComparison } from "./ResultComparison";
import { CRMFieldType } from "../../../../../../util/enums";

enum NodeType {
  ScreeningReasoning,
  Screening,
  ContentReasoning,
  Content,
}

const NodeLabel = {
  [NodeType.ScreeningReasoning]: "Screening Reasoning",
  [NodeType.Screening]: "Screening",
  [NodeType.ContentReasoning]: "Content Reasoning",
  [NodeType.Content]: "Content",
};

const NodeKey = {
  [NodeType.ScreeningReasoning]: "screening_reasoning",
  [NodeType.Screening]: "screening",
  [NodeType.ContentReasoning]: "content_reasoning",
  [NodeType.Content]: "content",
};

const createComparisonNode = (
  passed: boolean | null,
  nodeType: NodeType,
  actualContent: unknown,
  expectedContent: unknown,
  context: string | null,
  fieldType: CRMFieldType,
  picklistValues: Pick<PicklistDefinition, "label" | "value">[]
) => {
  return passed !== null ? (
    <Collapse
      expandIconPosition="start"
      defaultActiveKey={passed ? [] : [NodeKey[nodeType]]}
      items={[
        {
          key: NodeKey[nodeType],
          label: (
            <Typography.Text strong>
              {NodeLabel[nodeType]} {passed ? "Passed" : "Failed"}
            </Typography.Text>
          ),
          children: (
            <ResultComparison
              expected={{
                crm_field_value: expectedContent,
                crm_field_type: fieldType,
                crm_field_picklist_values: picklistValues,
              }}
              actual={{
                crm_field_value: actualContent,
                crm_field_type: fieldType,
                crm_field_picklist_values: picklistValues,
              }}
              context={context}
            />
          ),
        },
      ]}
    />
  ) : null;
};

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  testResult: FieldConfigurationPlaygroundResponse;
  fieldDefinition: FieldDefinition;
  fieldToTest: FieldTestOutcomeConfigurationWithMeeting;
}

export function ReviewResultDrawer({
  open,
  setOpen,
  testResult,
  fieldDefinition,
  fieldToTest,
}: Props) {
  const { token } = theme.useToken();

  const events = [
    {
      children: createComparisonNode(
        get(
          testResult,
          "meta.comparison_results.screening_reasoning_result.passed",
          null
        ),
        NodeType.ScreeningReasoning,
        get(testResult, "generated_content.screening_context", null),
        get(fieldToTest, "screening_expected_reasoning", ""),
        get(
          testResult,
          "meta.comparison_results.screening_reasoning_result.reasoning",
          null
        ),
        CRMFieldType.Textarea,
        []
      ),
      color: get(
        testResult,
        "meta.comparison_results.screening_reasoning_result.passed",
        null
      )
        ? token.colorSuccess
        : token.colorError,
    },
    {
      children: createComparisonNode(
        get(
          testResult,
          "meta.comparison_results.screening_result.passed",
          null
        ),
        NodeType.Screening,
        get(testResult, "screening_meta.screening_passed", null),
        get(fieldToTest, "screening_expected_outcome", null),
        null,
        CRMFieldType.Boolean,
        []
      ),
      color: get(
        testResult,
        "meta.comparison_results.screening_result.passed",
        null
      )
        ? token.colorSuccess
        : token.colorError,
    },
    {
      children: createComparisonNode(
        get(
          testResult,
          "meta.comparison_results.outcome_reasoning_result.passed",
          null
        ),
        NodeType.ContentReasoning,
        get(testResult, "generated_content.context", null),
        get(fieldToTest, "content_expected_reasoning", ""),
        get(
          testResult,
          "meta.comparison_results.outcome_reasoning_result.reasoning",
          null
        ),
        CRMFieldType.Textarea,
        []
      ),
      color: get(
        testResult,
        "meta.comparison_results.outcome_reasoning_result.passed",
        null
      )
        ? token.colorSuccess
        : token.colorError,
    },
    {
      children: createComparisonNode(
        get(testResult, "meta.comparison_results.outcome_result.passed", null),
        NodeType.Content,
        // Create a mutable copy of the array if it's an array
        Array.isArray(get(testResult, "generated_content.content", null))
          ? [...get(testResult, "generated_content.content", null)]
          : get(testResult, "generated_content.content", null),
        convertJsonContentToValue(
          fieldDefinition.field_type,
          get(fieldToTest, "content_expected_outcome", null)
        ),
        get(
          testResult,
          "meta.comparison_results.outcome_result.reasoning",
          null
        ),
        fieldDefinition.field_type,
        fieldDefinition.picklist_definitions
      ),
      color: get(
        testResult,
        "meta.comparison_results.outcome_result.passed",
        null
      )
        ? token.colorSuccess
        : token.colorError,
    },
  ];

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      title="Review Result"
      width="75rem"
    >
      <Timeline items={filter(events, (event) => !isNull(event.children))} />
    </Drawer>
  );
}
