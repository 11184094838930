import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WorkflowActionType } from "../../util/enums";
import { faSlack, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import {
  faWandMagicSparkles,
  faHashtag,
  faFileCirclePlus,
  faFilePen,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

export function WorkflowActionTypeIcon({
  actionType,
  size = "3x",
}: {
  actionType: WorkflowActionType;
  size?: SizeProp;
}) {
  switch (actionType) {
    case WorkflowActionType.Trigger:
      return <FontAwesomeIcon icon={faBolt} size={size} color="#2A2E4B" />;
    case WorkflowActionType.CreateCRMRecord:
      return (
        <FontAwesomeIcon icon={faFileCirclePlus} size={size} color="#2A2E4B" />
      );
    case WorkflowActionType.UpdateCRMRecord:
      return <FontAwesomeIcon icon={faFilePen} size={size} color="#2A2E4B" />;
    case WorkflowActionType.GenerateContent:
      return (
        <FontAwesomeIcon
          icon={faWandMagicSparkles}
          size={size}
          color="#2A2E4B"
        />
      );
    case WorkflowActionType.CreateSlackChannel:
      return <FontAwesomeIcon icon={faHashtag} size={size} color="#2A2E4B" />;
    case WorkflowActionType.SendSlackMessage:
      return (
        <FontAwesomeIcon
          icon={faSlack as IconProp}
          size={size}
          color="#2A2E4B"
        />
      );
    case WorkflowActionType.SendTeamsMessage:
      return (
        <FontAwesomeIcon
          icon={faMicrosoft as IconProp}
          size={size}
          color="#2A2E4B"
        />
      );
  }

  return;
}
