import React from "react";
import { useSlackChannels } from "../../../../../util/data_hooks";
import { DynamicVariableSelect } from "./dynamic_variables/DynamicVariableSelect";
import SkeletonInput from "antd/es/skeleton/Input";

export function SlackChannelsSelect({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  const { channels, isLoading } = useSlackChannels();

  if (isLoading) return <SkeletonInput active style={{ width: 500 }} />;

  return (
    <DynamicVariableSelect
      staticOptions={channels.map(
        ({ channel_id, channel_name, is_private }) => {
          return {
            label: `#${channel_name}${is_private ? " (Private)" : ""}`,
            value: channel_id,
          };
        }
      )}
      value={value}
      onChange={onChange}
    />
  );
}
