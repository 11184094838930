import React from "react";
import { Button, Empty } from "antd";
import { Integration } from "../../../../../util/enums";
import { useIntegrationWindow } from "../../../../hooks/useIntegrationWindow";

export function ConnectSlackMessage() {
  const [openIntegrationWindow] = useIntegrationWindow();

  return (
    <Empty description="Slack is not connected for your organization">
      <Button
        type="primary"
        onClick={() => openIntegrationWindow(Integration.Slack)}
      >
        Connect Slack
      </Button>
    </Empty>
  );
}
