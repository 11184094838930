import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Flex,
  Popover,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useOrganizationPlaygroundMeetings } from "../../../../../util/data_hooks";
import { Meeting } from "../../../../../util/types";
import { MeetingPreview } from "./MeetingPreview";
import { UploadMeetingRecordingButton } from "../../../../shared/UploadMeetingRecordingButton";
import { Integration } from "../../../../../util/enums";
import { MeetingFilter } from "./MeetingFilter";
import { usePlaygroundMeetingFilter } from "./usePlaygroundMeetingFilter";
import { TestMeeting } from "../../../../redux/features/PlaygroundFieldTesting/playgroundSlice";

interface Props {
  testMeeting: (meeting: TestMeeting) => Promise<void>;
}

export function MeetingSelect({ testMeeting }: Props) {
  const [selectedMeeting, selectMeeting] = useState<Meeting>(null);
  const {
    page,
    pageSize,
    debouncedMeetingName,
    teamFilterUuids,
    updateMeetingsPageSize,
    updateMeetingsPage,
  } = usePlaygroundMeetingFilter();
  const {
    meetings,
    meeting_summaries,
    isLoading,
    meta,
    mutate: mutateOrganizationAssemblyMeetings,
  } = useOrganizationPlaygroundMeetings(
    page,
    pageSize,
    teamFilterUuids,
    debouncedMeetingName
  );

  const getMeetingSummary = (uuid: string) => meeting_summaries[uuid] || "";

  const canTestMeeting = (meeting: Meeting) =>
    meeting.assemblyai_transcript_id ||
    meeting.provider_name === Integration.Gong ||
    meeting.provider_name === Integration.Chorus;

  const pagination: TablePaginationConfig = {
    current: page + 1,
    pageSize: pageSize,
    total: meta.total,
    onChange: (newPage, newPageSize) => {
      if (page !== newPage - 1) {
        updateMeetingsPage(newPage - 1);
      } else {
        updateMeetingsPageSize(newPageSize);
      }
    },
    pageSizeOptions: [10, 20, 50, 100],
    showSizeChanger: true,
  };

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: Meeting[]) => {
      selectMeeting(selectedRows[0] || null);
    },
    getCheckboxProps: (meeting: Meeting) => {
      return {
        disabled: !canTestMeeting(meeting),
      };
    },
  };

  const columns: ColumnsType<Meeting> = [
    {
      title: "Name",
      dataIndex: "topic",
      key: "name",
      width: "50%",
    },
    {
      title: "User",
      key: "user",
      dataIndex: "user_email",
    },
    {
      key: "info",
      render: (_, meeting) =>
        canTestMeeting(meeting) ? (
          <Space>
            <Popover
              title={meeting.topic}
              placement="topRight"
              arrow={{ pointAtCenter: true }}
              content={
                <MeetingPreview
                  meeting={meeting}
                  summary={getMeetingSummary(meeting.uuid)}
                />
              }
              trigger="click"
            >
              <InfoCircleOutlined />
            </Popover>
          </Space>
        ) : (
          <Tooltip title="Transcription in progress...">
            <LoadingOutlined style={{ fontSize: 18 }} spin />
          </Tooltip>
        ),
    },
  ];

  return (
    <>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Card>
            <Row justify="space-between" align="middle">
              <Col>
                {selectedMeeting === null ? (
                  <Typography.Text type="secondary">
                    Select a meeting to test
                  </Typography.Text>
                ) : (
                  <Typography.Text>{selectedMeeting.topic}</Typography.Text>
                )}
              </Col>

              <Col>
                <Button
                  type="primary"
                  style={{ marginLeft: "auto" }}
                  disabled={selectedMeeting == null}
                  onClick={() => {
                    const meetingSummary = getMeetingSummary(
                      selectedMeeting.uuid
                    );

                    testMeeting({
                      meeting: selectedMeeting,
                      summary: meetingSummary,
                    });
                  }}
                >
                  Test Meeting
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Card
            title="Meeting Recordings"
            extra={
              <Flex style={{ width: "100%" }}>
                <UploadMeetingRecordingButton
                  isTestMeeting
                  onSuccess={mutateOrganizationAssemblyMeetings}
                />
                <div style={{ marginLeft: "0.25rem" }}>
                  <MeetingFilter />
                </div>
              </Flex>
            }
          >
            <Table<Meeting>
              rowKey={({ uuid }) => uuid}
              dataSource={meetings}
              columns={columns}
              pagination={pagination}
              loading={isLoading}
              onRow={(meeting) => {
                return {
                  onClick: () =>
                    canTestMeeting(meeting) && selectMeeting(meeting),
                  style: { cursor: "pointer" },
                };
              }}
              rowSelection={{
                type: "radio",
                selectedRowKeys: selectedMeeting ? [selectedMeeting.uuid] : [],
                ...rowSelection,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}
