import { Card, Divider, Typography } from "antd";
import { Splitter } from "antd";
import React from "react";
import { getFieldDiffs } from "../../../../../../util/auto_generated_field_helpers";
import { CRMField } from "../../../../../../util/types";
import { TruncatedContent } from "../../../../../meeting_form/auto_generated_field/TruncatedContent";
import { DiffTextMarkdown } from "../../../../../meeting_form/auto_generated_field/DiffText";

interface Props {
  expected: Pick<
    CRMField,
    "crm_field_value" | "crm_field_type" | "crm_field_picklist_values"
  >;
  actual: Pick<
    CRMField,
    "crm_field_value" | "crm_field_type" | "crm_field_picklist_values"
  >;
  context: string;
}

export function ResultComparison({ expected, actual, context }: Props) {
  const [sizes, setSizes] = React.useState<(number | string)[]>(["50%", "50%"]);

  const diffs = getFieldDiffs(expected, actual, false);
  const leftDiff = diffs.leftDiff;
  const rightDiff = diffs.rightDiff;

  return (
    <TruncatedContent maxHeight={200}>
      <Splitter onResize={setSizes}>
        <Splitter.Panel size={sizes[0]} style={{ marginRight: "0.25rem" }}>
          <Card title="Expected">
            <DiffTextMarkdown diff={leftDiff} />
          </Card>
        </Splitter.Panel>
        <Splitter.Panel size={sizes[1]} style={{ marginLeft: "0.25rem" }}>
          <Card title="Actual">
            <DiffTextMarkdown diff={rightDiff} />
          </Card>
        </Splitter.Panel>
      </Splitter>
      {context && (
        <>
          <Divider style={{ margin: "10px 0px" }}></Divider>

          <Typography.Paragraph
            type="secondary"
            style={{ fontSize: 12 }}
            ellipsis={{
              rows: 2,
              expandable: "collapsible",
            }}
          >
            <b>Context for comparison:</b> {context}
          </Typography.Paragraph>
        </>
      )}
    </TruncatedContent>
  );
}
