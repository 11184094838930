import React, { useState } from "react";
import { Card, Col, message, Modal, Row, Spin, Typography } from "antd";
import {
  WorkflowActionType,
  WorkflowTriggerType,
} from "../../../../util/enums";
import {
  fetchCreateWorkflowState,
  fetchCreateWorkflowTransition,
  fetchUpdateWorkflowTransition,
} from "../../../../util/api";
import { useNavigate, useParams } from "react-router-dom";
import { getFriendlyWorkflowActionLabel } from "../../../../helpers/label_maps";
import { WorkflowActionTypeIcon } from "../../../shared/WorkflowActionTypeIcon";
import { WorkflowState, WorkflowTransition } from "../../../../util/types";
import { useWorkflow } from "../../../../util/data_hooks";

interface WorkflowActionModalProps {
  open: boolean;
  workflowState: WorkflowState;
  workflowTransition?: WorkflowTransition;
  isTarget?: boolean;
  setOpen: (open: boolean) => void;
}

export function WorkflowActionModal({
  open,
  workflowState,
  workflowTransition = null,
  isTarget = true,
  setOpen,
}: WorkflowActionModalProps) {
  const navigate = useNavigate();
  const { workflowUuid } = useParams();

  const {
    workflow,
    mutate: mutateWorkflow,
    isLoading: isWorkflowLoading,
  } = useWorkflow(workflowUuid);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onActionSelect = async (actionType: WorkflowActionType) => {
    try {
      setIsSaving(true);
      const newWorkflowState = await fetchCreateWorkflowState(workflowUuid, {
        actionable_type: actionType,
        name: getFriendlyWorkflowActionLabel(actionType),
      });

      if (workflowTransition) {
        await fetchUpdateWorkflowTransition(workflowTransition.uuid, {
          workflow_state_uuid: workflowState.uuid,
          ...(isTarget
            ? {
                target_workflow_state_uuid: newWorkflowState.uuid,
              }
            : {
                fallback_target_workflow_state_uuid: newWorkflowState.uuid,
              }),
        });
      } else {
        await fetchCreateWorkflowTransition({
          workflow_state_uuid: workflowState.uuid,
          target_workflow_state_uuid: newWorkflowState.uuid,
        });
      }

      await mutateWorkflow();

      navigate(`workflow-states/${newWorkflowState.uuid}`);

      setOpen(false);
    } catch (e) {
      message.error(e.message);
    }

    setIsSaving(false);
  };

  const isLoading = isWorkflowLoading || isSaving;

  return (
    <Modal
      width="50rem"
      title="Select an Action"
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <Row gutter={[18, 18]}>
          {Object.values(WorkflowActionType)
            .filter((value) => {
              switch (value) {
                case WorkflowActionType.Trigger:
                  return false;
                case WorkflowActionType.GenerateContent:
                  return [WorkflowTriggerType.MessageCreated].includes(
                    workflow.triggerable_type
                  );
                default:
                  return true;
              }
            })
            .map((actionType) => {
              return (
                <Col span={12} key={actionType}>
                  <Card
                    onClick={() => onActionSelect(actionType)}
                    hoverable
                    style={{ paddingTop: 20, textAlign: "center" }}
                    cover={<WorkflowActionTypeIcon actionType={actionType} />}
                  >
                    <Typography.Text strong>
                      {getFriendlyWorkflowActionLabel(actionType)}
                    </Typography.Text>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </Spin>
    </Modal>
  );
}
