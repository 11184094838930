import React, { useEffect } from "react";
import { FieldTestOutcomeConfigurationDetail } from "../../../../../../util/types";
import { Button, Form, Input, Space, Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { CRMFieldType } from "../../../../../../util/enums";
import { useAppSelector } from "../../../../../redux/hooks";
import { MeetingFormFieldItem } from "../../../../../meeting_form/MeetingFormFieldItem";
import {
  convertContentToJson,
  convertJsonContentToValue,
} from "../../../../../../util/auto_generated_field_helpers";

interface Props {
  isCreate: boolean;
  handleSave: (
    testConfiguration: FieldTestOutcomeConfigurationDetail
  ) => Promise<void>;
  handleCancel: () => void;
  testConfiguration: FieldTestOutcomeConfigurationDetail;
}

export function TestOutcomeConfigForm({
  isCreate,
  testConfiguration,
  handleCancel,
  handleSave,
}: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [form] = Form.useForm();
  const screeningPassed = Form.useWatch("screening_expected_outcome", form);
  const [isFormModified, setIsFormModified] = React.useState(isCreate);
  const fieldDefinition = useAppSelector(
    (state) => state.playground.fieldDefinition
  );
  const fieldToTest = useAppSelector((state) => state.playground.fieldToTest);

  const contentReasoningNeeded = ![
    CRMFieldType.Textarea,
    CRMFieldType.HTML,
    CRMFieldType.String,
  ].includes(fieldDefinition.field_type);
  const screeningNeeded = fieldToTest.screening_enabled;

  React.useEffect(() => {
    if (!isCreate) {
      form.setFieldsValue(testConfiguration);
      setIsFormModified(false);
    }
  }, [testConfiguration, form]);

  const handleFormValuesChange = () => {
    const currentValues = form.getFieldsValue();
    const hasChanges =
      JSON.stringify(currentValues) !== JSON.stringify(testConfiguration);
    setIsFormModified(hasChanges || isCreate);
  };

  const submitForm = async (values: FieldTestOutcomeConfigurationDetail) => {
    setIsLoading(true);

    // Clean up non-required empty fields
    const cleanedValues = { ...values };
    if (cleanedValues.screening_expected_outcome === undefined) {
      cleanedValues.screening_expected_outcome = null;
    }
    if (
      cleanedValues.screening_expected_reasoning === undefined ||
      cleanedValues.screening_expected_reasoning === ""
    ) {
      cleanedValues.screening_expected_reasoning = null;
    }
    if (
      cleanedValues.content_expected_reasoning === undefined ||
      cleanedValues.content_expected_reasoning === ""
    ) {
      cleanedValues.content_expected_reasoning = null;
    }
    if (cleanedValues.content_expected_outcome === undefined) {
      cleanedValues.content_expected_outcome = null;
    } else {
      cleanedValues.content_expected_outcome = convertContentToJson(
        fieldDefinition.field_type,
        cleanedValues.content_expected_outcome
      );
    }

    await handleSave(cleanedValues);
    setIsLoading(false);
  };

  // Set initial form values
  useEffect(() => {
    if (fieldDefinition && testConfiguration) {
      const initialValues = {
        ...testConfiguration,
        content_expected_outcome: convertJsonContentToValue(
          fieldDefinition.field_type,
          testConfiguration.content_expected_outcome
        ),
      };
      form.setFieldsValue(initialValues);
    }
  }, [fieldDefinition, testConfiguration]);

  return (
    <Form<FieldTestOutcomeConfigurationDetail>
      onFinish={submitForm}
      form={form}
      layout={"vertical"}
      onValuesChange={handleFormValuesChange}
    >
      {screeningNeeded && (
        <>
          <Form.Item
            name="screening_expected_outcome"
            label="Expected screening result?"
            valuePropName="checked"
            rules={[{ required: true }]}
          >
            <Switch
              checkedChildren={
                <>
                  <CheckOutlined /> Pass
                </>
              }
              unCheckedChildren={
                <>
                  <CloseOutlined /> Fail
                </>
              }
            />
          </Form.Item>

          <Form.Item
            name="screening_expected_reasoning"
            label="Reasoning for screening result"
          >
            <Input.TextArea rows={10} />
          </Form.Item>
        </>
      )}
      {(!screeningNeeded || screeningPassed) && (
        <>
          <MeetingFormFieldItem
            name={"content_expected_outcome"}
            label={"Expected content result"}
            fieldType={fieldDefinition.field_type}
            required={true}
            length={fieldDefinition.length}
            picklistItems={fieldDefinition.picklist_definitions}
            disabled={false}
            showLabel={true}
          />
          {contentReasoningNeeded && (
            <Form.Item
              name="content_expected_reasoning"
              label="Reasoning for content result"
              rules={[
                { required: contentReasoningNeeded, message: "Required" },
              ]}
            >
              <Input.TextArea rows={10} />
            </Form.Item>
          )}
        </>
      )}
      <Space>
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          disabled={!isFormModified}
        >
          Save
        </Button>

        <Button
          onClick={() => {
            form.resetFields();
            handleCancel();
          }}
        >
          Cancel
        </Button>
      </Space>
    </Form>
  );
}
