import React from "react";
import { Select } from "antd";
import { useCRMObjects } from "../../../../../util/data_hooks";
import { CRMProvider } from "../../../../../util/types";
import SkeletonInput from "antd/es/skeleton/Input";

interface CRMObjectSelectProps {
  crm: CRMProvider;
  value: string;
  onChange: (crmObjectType: string) => void;
}

export function CRMObjectSelect({
  crm,
  value,
  onChange,
}: CRMObjectSelectProps) {
  const { objects, isLoading } = useCRMObjects(crm);

  if (isLoading) return <SkeletonInput active style={{ width: 500 }} />;

  return (
    <Select
      allowClear
      showSearch
      placeholder="Select an object type..."
      options={objects.map(({ crm_object_label, crm_object_name }) => {
        return {
          label: `${crm_object_label} (${crm_object_name})`,
          value: crm_object_name,
        };
      })}
      value={value}
      onChange={onChange}
    />
  );
}
