import React from "react";
import { FieldConfigurationPlaygroundResponse } from "../../../../../../util/types";
import { Skeleton, Steps, StepsProps, Typography } from "antd";

interface Props {
  isLoading: boolean;
  testResults: FieldConfigurationPlaygroundResponse;
}

const labelMap = {
  screening_reasoning_result: "Screening Reasoning",
  screening_result: "Screening",
  outcome_reasoning_result: "Result Reasoning",
  outcome_result: "Result",
};

export function ResultPreview({ isLoading, testResults }: Props) {
  if (isLoading) {
    return (
      <div
        style={{ width: "80%", marginTop: "0.5rem", marginBottom: "0.5rem" }}
      >
        <Skeleton.Button shape="round" active size="small" block />
      </div>
    );
  }

  const didError = testResults?.error;

  if (didError) {
    return (
      <Typography.Text type="secondary">
        - An error occurred while running this test
      </Typography.Text>
    );
  }

  const comparisonResults = testResults?.meta?.comparison_results;
  const resultSteps: StepsProps["items"] = [];

  Object.entries(labelMap).forEach(([key, label]) => {
    const comparisonResult = comparisonResults[key];
    if (comparisonResult.passed !== null) {
      resultSteps.push({
        title: label,
        description: comparisonResult.passed ? "Passed" : "Failed",
        status: comparisonResult.passed ? "process" : "error",
        disabled: true, // TODO: Allow user to click to view reasoning
      });
    }
  });

  return (
    <Steps
      style={{
        marginTop: 8,
      }}
      type="inline"
      items={resultSteps}
    />
  );
}
