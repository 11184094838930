import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Switch,
  Typography,
} from "antd";
import React, { useCallback, useState } from "react";
import {
  useFieldConfigurationGroups,
  useFieldDefinition,
  useGenerateContentAction,
  useWorkflowActionFieldConfigurations,
} from "../../../../util/data_hooks";
import {
  CRMFieldType,
  FieldConfigurationAppendType,
} from "../../../../util/enums";
import { DynamicVariableTextarea } from "./shared/dynamic_variables/DynamicVariableTextarea";
import {
  CloseCircleOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { DynamicVariableSelect } from "./shared/dynamic_variables/DynamicVariableSelect";
import {
  createWorkflowActionFieldConfiguration,
  deleteWorkflowActionFieldConfiguration,
  replaceUndefinedValuesWithNull,
  updateGenerateContentAction,
  updateWorkflowActionFieldConfiguration,
} from "../../../../util/api";
import { getFriendlyCRMFieldTypeLabel } from "../../../../helpers/label_maps";
import { debounce } from "lodash";
import { useWorkflowStateContext } from "./shared/WorkflowStateContext";
import { DEBOUNCE_DELAY } from "../shared/WorkflowStateEditorDrawer";

enum GenerateContentActionAttribute {
  SystemPrompt = "system_prompt",
}

enum WorkflowActionFieldConfigurationAttribute {
  Uuid = "uuid",
  FieldDefinitionUuid = "field_definition_uuid",
  ScreeningEnabled = "screening_enabled",
  ScreeningPrompt = "screening_prompt",
  UserPrompt = "user_prompt",
  AppendType = "append_type",
  ContentToAppend = "content_to_append",
}

export interface GenerateContentAction {
  [GenerateContentActionAttribute.SystemPrompt]: string;
}

export interface WorkflowActionFieldConfiguration {
  [WorkflowActionFieldConfigurationAttribute.Uuid]: string;
  [WorkflowActionFieldConfigurationAttribute.FieldDefinitionUuid]: string;
  [WorkflowActionFieldConfigurationAttribute.ScreeningEnabled]: boolean;
  [WorkflowActionFieldConfigurationAttribute.ScreeningPrompt]: string;
  [WorkflowActionFieldConfigurationAttribute.UserPrompt]: string;
  [WorkflowActionFieldConfigurationAttribute.AppendType]: string;
  [WorkflowActionFieldConfigurationAttribute.ContentToAppend]: string;
}

export function GenerateContent() {
  const [form] = Form.useForm<GenerateContentAction>();
  const { workflowUuid } = useParams();

  const { workflowState, setIsSaving, afterSave } = useWorkflowStateContext();

  const {
    action,
    mutate: mutateAction,
    isLoading: isActionLoading,
  } = useGenerateContentAction(workflowUuid, workflowState.actionable_id);

  const {
    fieldConfigurationGroups,
    isLoading: isFieldConfigurationGroupsLoading,
  } = useFieldConfigurationGroups();

  const {
    workflowActionFieldConfigurations,
    isLoading: isWorkflowActionFieldConfigurationsLoading,
    mutate: mutateWorkflowActionFieldConfigurations,
  } = useWorkflowActionFieldConfigurations(workflowUuid, workflowState.uuid);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [systemPromptVisible, setSystemPromptVisible] =
    useState<boolean>(false);
  const [
    newWorkflowActionFieldConfigurationUuid,
    setNewWorkflowActionFieldConfigurationUuid,
  ] = useState<string>(null);

  const isLoading =
    isFieldConfigurationGroupsLoading ||
    isActionLoading ||
    isWorkflowActionFieldConfigurationsLoading;

  const debouncedHandleSubmit = useCallback(
    debounce(async () => {
      try {
        setIsSaving(true);
        await updateGenerateContentAction(
          workflowUuid,
          workflowState.actionable_id,
          form.getFieldsValue()
        );

        await mutateAction();
        await afterSave();
      } catch (e) {
        message.error(e.message);
      }
      setIsSaving(false);
    }, DEBOUNCE_DELAY),
    [workflowUuid, workflowState.actionable_id, mutateAction, afterSave]
  );

  if (isLoading) return <Skeleton active />;

  return (
    <>
      <Modal
        title="Add Field Automation"
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={null}
        destroyOnClose
      >
        <Select
          ref={(input) => input && input.focus()}
          defaultOpen
          style={{ width: "100%" }}
          placeholder="Select a field definition"
          showSearch
          optionFilterProp="label"
          options={fieldConfigurationGroups.map(
            ({ label, field_definitions }) => {
              return {
                label,
                options: field_definitions.map(({ uuid, label }) => {
                  return { value: uuid, label };
                }),
              };
            }
          )}
          onSelect={async (value) => {
            try {
              setIsSaving(true);
              const { uuid } = await createWorkflowActionFieldConfiguration(
                workflowUuid,
                workflowState.uuid,
                { field_definition_uuid: value }
              );

              setNewWorkflowActionFieldConfigurationUuid(uuid);

              await mutateWorkflowActionFieldConfigurations();
              afterSave();

              setModalOpen(false);
            } catch (e) {
              message.error(e.message);
            }
            setIsSaving(false);
          }}
        />
      </Modal>

      <div style={{ marginBottom: 15 }}>
        <Typography.Title level={4} style={{ marginTop: 0 }}>
          Context
        </Typography.Title>

        <Space>
          <Typography.Text type="secondary">
            Context is used when generating the field automations below.
          </Typography.Text>

          <Typography.Link
            onClick={() => setSystemPromptVisible(!systemPromptVisible)}
          >
            {systemPromptVisible ? "Hide Context" : "Show Context"}
          </Typography.Link>
        </Space>
      </div>

      <Form
        form={form}
        initialValues={action}
        onValuesChange={debouncedHandleSubmit}
      >
        <Form.Item
          hidden={!systemPromptVisible}
          name={GenerateContentActionAttribute.SystemPrompt}
        >
          <DynamicVariableTextarea
            value={form.getFieldValue(
              GenerateContentActionAttribute.SystemPrompt
            )}
            onChange={(value) => {
              form.setFieldValue(
                GenerateContentActionAttribute.SystemPrompt,
                value
              );
            }}
          />
        </Form.Item>
      </Form>

      <Divider />

      <Row style={{ marginBottom: 15 }} justify="space-between" align="middle">
        <Col>
          <Typography.Title
            level={4}
            style={{ marginTop: 0, marginBottom: 10 }}
          >
            Field Automations
          </Typography.Title>

          <Typography.Text type="secondary">
            Generate content for your imported field definitions.
          </Typography.Text>
        </Col>

        <Col>
          <Button
            type="primary"
            onClick={() => setModalOpen(true)}
            icon={<PlusCircleOutlined />}
          >
            Add Field Automation
          </Button>
        </Col>
      </Row>

      {workflowActionFieldConfigurations.length === 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Add a field automation to generate content for any of your field definitions"
        >
          <Button onClick={() => setModalOpen(true)}>
            Add Field Automation
          </Button>
        </Empty>
      ) : (
        <Row gutter={[24, 24]}>
          {workflowActionFieldConfigurations.map(
            (workflowActionFieldConfiguration) => (
              <Col key={workflowActionFieldConfiguration.uuid} span={24}>
                <FieldConfigurationCard
                  defaultCollapsed={
                    workflowActionFieldConfiguration.uuid !==
                    newWorkflowActionFieldConfigurationUuid
                  }
                  workflowActionFieldConfiguration={
                    workflowActionFieldConfiguration
                  }
                />
              </Col>
            )
          )}
        </Row>
      )}
    </>
  );
}

function FieldConfigurationCard({
  workflowActionFieldConfiguration,
  defaultCollapsed,
}: {
  workflowActionFieldConfiguration: WorkflowActionFieldConfiguration;
  defaultCollapsed: boolean;
}) {
  const [form] = Form.useForm<WorkflowActionFieldConfiguration>();

  const { workflowState, setIsSaving, afterSave } = useWorkflowStateContext();

  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(defaultCollapsed);

  const { workflowUuid } = useParams();

  const { mutate: mutateWorkflowActionFieldConfigurations } =
    useWorkflowActionFieldConfigurations(workflowUuid, workflowState.uuid);

  const { fieldDefinition, isLoading: isFieldDefinitionLoading } =
    useFieldDefinition(workflowActionFieldConfiguration.field_definition_uuid);

  const screeningEnabled = Form.useWatch(
    WorkflowActionFieldConfigurationAttribute.ScreeningEnabled,
    form
  );

  const appendType = Form.useWatch(
    WorkflowActionFieldConfigurationAttribute.AppendType,
    form
  );

  const debouncedHandleSubmit = useCallback(
    debounce(async () => {
      try {
        setIsSaving(true);

        await updateWorkflowActionFieldConfiguration(
          workflowUuid,
          workflowState.uuid,
          workflowActionFieldConfiguration.uuid,
          replaceUndefinedValuesWithNull(form.getFieldsValue())
        );

        afterSave();
      } catch (e) {
        message.error(e.message);
      }

      setIsSaving(false);
    }, DEBOUNCE_DELAY),
    [workflowUuid, workflowState.actionable_id, afterSave]
  );

  if (isFieldDefinitionLoading) return <Skeleton active />;

  return (
    <Card
      title={`${fieldDefinition.field_configuration_group.label} ${
        fieldDefinition.label
      } (${getFriendlyCRMFieldTypeLabel(fieldDefinition.field_type)})`}
      size="small"
      extra={
        <Button
          loading={isRemoving}
          danger
          type="text"
          size="small"
          key="remove-field"
          icon={<CloseCircleOutlined />}
          onClick={async () => {
            try {
              setIsRemoving(true);

              await deleteWorkflowActionFieldConfiguration(
                workflowUuid,
                workflowState.uuid,
                workflowActionFieldConfiguration.uuid
              );

              await mutateWorkflowActionFieldConfigurations();
              afterSave();

              message.success(
                `"${fieldDefinition.label}" Configuration Removed`
              );
            } catch (e) {
              message.error(e.message);
            }

            setIsRemoving(false);
          }}
        >
          Remove
        </Button>
      }
    >
      {!collapsed && (
        <Form
          form={form}
          layout="vertical"
          initialValues={workflowActionFieldConfiguration}
          onValuesChange={debouncedHandleSubmit}
        >
          <Form.Item
            label="Screen Field"
            name={WorkflowActionFieldConfigurationAttribute.ScreeningEnabled}
            initialValue={false}
          >
            <Switch />
          </Form.Item>

          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                label="Screening Prompt"
                hidden={!screeningEnabled}
                name={WorkflowActionFieldConfigurationAttribute.ScreeningPrompt}
                initialValue={null}
              >
                <DynamicVariableTextarea
                  value={form.getFieldValue(
                    WorkflowActionFieldConfigurationAttribute.ScreeningPrompt
                  )}
                  onChange={(value) => {
                    form.setFieldValue(
                      WorkflowActionFieldConfigurationAttribute.ScreeningPrompt,
                      value
                    );
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={screeningEnabled ? 12 : 24}>
              <Form.Item
                label="Prompt"
                name={WorkflowActionFieldConfigurationAttribute.UserPrompt}
              >
                <DynamicVariableTextarea
                  value={form.getFieldValue(
                    WorkflowActionFieldConfigurationAttribute.UserPrompt
                  )}
                  onChange={(value) => {
                    form.setFieldValue(
                      WorkflowActionFieldConfigurationAttribute.UserPrompt,
                      value
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {[
            CRMFieldType.Textarea,
            CRMFieldType.String,
            CRMFieldType.Multipicklist,
          ].includes(fieldDefinition.field_type) && (
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Append Content? (Optional)"
                  name={WorkflowActionFieldConfigurationAttribute.AppendType}
                >
                  <Select
                    allowClear
                    placeholder="Should this value be appended to another value? (Optional)"
                    options={
                      fieldDefinition.field_type === CRMFieldType.Multipicklist
                        ? [
                            {
                              label: "Merge",
                              value: FieldConfigurationAppendType.Merge,
                            },
                          ]
                        : [
                            {
                              label: "Combine Intelligently",
                              value: FieldConfigurationAppendType.Smart,
                            },
                            {
                              label: "Append to Top",
                              value: FieldConfigurationAppendType.Top,
                            },
                            {
                              label: "Append to Bottom",
                              value: FieldConfigurationAppendType.Bottom,
                            },
                          ]
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  hidden={!appendType}
                  label="Content to Append"
                  name={
                    WorkflowActionFieldConfigurationAttribute.ContentToAppend
                  }
                >
                  <DynamicVariableSelect
                    value={form.getFieldValue(
                      WorkflowActionFieldConfigurationAttribute.ContentToAppend
                    )}
                    onChange={(value) => {
                      form.setFieldValue(
                        WorkflowActionFieldConfigurationAttribute.ContentToAppend,
                        value
                      );
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          {[
            CRMFieldType.Picklist,
            CRMFieldType.Multipicklist,
            CRMFieldType.Reference,
          ].includes(fieldDefinition.field_type) && (
            <Typography.Paragraph>
              Picklist Options:{" "}
              {fieldDefinition.picklist_definitions
                .map(({ label }) => label)
                .join(", ")}
            </Typography.Paragraph>
          )}
        </Form>
      )}

      <Typography.Link onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? <EditOutlined /> : <MinusCircleOutlined />}{" "}
        {collapsed ? "Edit Configuration" : "Hide Configuration"}
      </Typography.Link>
    </Card>
  );
}
