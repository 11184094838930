import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Empty,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Typography,
} from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { CRMField, CRMProvider } from "../../../../../util/types";
import { CRMFieldType, WorkflowActionType } from "../../../../../util/enums";
import { DynamicVariableTextarea } from "./dynamic_variables/DynamicVariableTextarea";
import { DynamicVariableSelect } from "./dynamic_variables/DynamicVariableSelect";
import {
  useCRMObjectFields,
  useWorkflowActionCrmFields,
} from "../../../../../util/data_hooks";
import {
  createWorkflowActionCrmField,
  deleteWorkflowActionCrmField,
  replaceUndefinedValuesWithNull,
  updateWorkflowActionCrmField,
} from "../../../../../util/api";
import { useParams } from "react-router-dom";
import { useWorkflowStateContext } from "./WorkflowStateContext";
import { debounce } from "lodash";
import { DEBOUNCE_DELAY } from "../../shared/WorkflowStateEditorDrawer";

interface CRMFieldValuesFormItemProps {
  title: string;
  subTitle: string;
  crm: CRMProvider;
  crmObjectType: string;
  showRequiredFields?: boolean;
}

export function WorkflowActionCrmFields({
  title,
  subTitle,
  crm,
  crmObjectType,
  showRequiredFields,
}: CRMFieldValuesFormItemProps) {
  const { workflowUuid } = useParams();

  const { workflowState, afterSave, setIsSaving } = useWorkflowStateContext();

  const [fieldSelectModalOpen, setFieldSelectModalOpen] =
    useState<boolean>(false);

  const { objectFields, isLoading: isCrmObjectFieldsLoading } =
    useCRMObjectFields(crm, crmObjectType, true);

  const {
    workflowActionCrmFields,
    isLoading: isWorkflowActionCrmFieldsLoading,
    mutate: mutateWorkflowActionCrmFields,
  } = useWorkflowActionCrmFields(workflowUuid, workflowState.uuid);

  useEffect(() => {
    mutateWorkflowActionCrmFields();
  }, [crmObjectType]);

  const handleModalClose = () => {
    setFieldSelectModalOpen(false);
  };

  const isLoading =
    isWorkflowActionCrmFieldsLoading || isCrmObjectFieldsLoading;

  if (isLoading) return <Skeleton active />;

  return (
    <>
      <Modal
        destroyOnClose
        open={fieldSelectModalOpen}
        onCancel={handleModalClose}
        title="Add Field"
        footer={[
          <Button key="cancel" onClick={handleModalClose}>
            Cancel
          </Button>,
        ]}
      >
        <Select
          ref={(input) => input && input.focus()}
          defaultOpen
          onSelect={async (value) => {
            try {
              setIsSaving(true);

              await createWorkflowActionCrmField(
                workflowUuid,
                workflowState.uuid,
                { crm_field_name: value }
              );

              await mutateWorkflowActionCrmFields();

              setFieldSelectModalOpen(false);

              afterSave();
            } catch (e) {
              message.error(e.message);
            }

            setIsSaving(false);
          }}
          optionFilterProp="label"
          showSearch
          style={{ width: "100%" }}
          placeholder="Select a CRM field..."
          options={objectFields.map(({ crm_field_label, crm_field_name }) => {
            return {
              label: `${crm_field_label} (${crm_field_name})`,
              value: crm_field_name,
            };
          })}
        />
      </Modal>

      <Row style={{ marginBottom: 15 }} justify="space-between" align="middle">
        <Col>
          <Typography.Title
            level={4}
            style={{ marginTop: 0, marginBottom: 10 }}
          >
            {title}
          </Typography.Title>

          <Typography.Text type="secondary">{subTitle}</Typography.Text>
        </Col>

        <Col>
          <Button
            type="primary"
            onClick={() => setFieldSelectModalOpen(true)}
            icon={<PlusCircleOutlined />}
          >
            Add Field Value
          </Button>
        </Col>
      </Row>

      {workflowActionCrmFields.length === 0 ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No field values found"
        >
          <Button onClick={() => setFieldSelectModalOpen(true)}>
            Add Field Value
          </Button>
        </Empty>
      ) : (
        <>
          {workflowActionCrmFields.map((workflowActionCrmField) => {
            const crmField = objectFields.find(
              (field) =>
                field.crm_field_name === workflowActionCrmField.crm_field_name
            );

            if (!crmField) return null;

            return (
              <CRMFieldValueCard
                key={workflowActionCrmField.uuid}
                workflowActionCrmField={workflowActionCrmField}
                crmField={crmField}
                showRequiredFields={showRequiredFields}
              />
            );
          })}
        </>
      )}
    </>
  );
}

export interface WorkflowActionCrmField {
  uuid: string;
  crm_field_name: string;
  crm_field_value: string;
  overwrite_existing_value: boolean;
}

function CRMFieldValueCard({
  crmField,
  workflowActionCrmField,
  showRequiredFields,
}: {
  crmField: CRMField;
  workflowActionCrmField: WorkflowActionCrmField;
  showRequiredFields: boolean;
}) {
  const { workflowUuid } = useParams();
  const { workflowState, setIsSaving, afterSave } = useWorkflowStateContext();

  const { mutate: mutateWorkflowActionCrmFields } = useWorkflowActionCrmFields(
    workflowUuid,
    workflowState.uuid
  );

  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const [value, setValue] = useState<string>(
    workflowActionCrmField.crm_field_value
  );

  const {
    crm_field_name,
    crm_field_label,
    crm_field_type,
    crm_field_required,
    crm_field_picklist_values,
  } = crmField;

  const debouncedHandleSubmit = useCallback(
    debounce(async (values: Partial<WorkflowActionCrmField>) => {
      try {
        setIsSaving(true);

        await updateWorkflowActionCrmField(
          workflowUuid,
          workflowState.uuid,
          workflowActionCrmField.uuid,
          replaceUndefinedValuesWithNull(values)
        );

        await mutateWorkflowActionCrmFields();
        await afterSave();
      } catch (e) {
        message.error(e.message);
      }

      setIsSaving(false);
    }, DEBOUNCE_DELAY),
    [
      workflowUuid,
      workflowState.uuid,
      workflowActionCrmField.uuid,
      mutateWorkflowActionCrmFields,
      afterSave,
    ]
  );

  useEffect(() => {
    if (value === workflowActionCrmField.crm_field_value) return;
    debouncedHandleSubmit({ crm_field_value: value });
  }, [value]);

  const isRequired = showRequiredFields && crm_field_required;

  const showOverwriteOptions =
    workflowState.actionable_type === WorkflowActionType.UpdateCRMRecord;

  let staticOptions = [];

  switch (crm_field_type) {
    case CRMFieldType.Picklist:
    case CRMFieldType.Multipicklist:
    case CRMFieldType.Reference:
      staticOptions = crm_field_picklist_values.map(({ label, value }) => {
        return { label, value };
      });
      break;
    case CRMFieldType.Boolean:
      staticOptions = [
        { label: "True", value: true },
        { label: "False", value: false },
      ];
  }

  return (
    <Card
      style={{ marginBottom: 20 }}
      size="small"
      title={
        <Row justify="space-between">
          <Col>
            <Row gutter={[12, 0]} align="middle">
              <Col>
                <Typography.Text>
                  {crm_field_label}
                  {crm_field_label !== crm_field_name
                    ? ` (${crm_field_name})`
                    : ""}
                </Typography.Text>
              </Col>

              {showOverwriteOptions && (
                <Col>
                  <Select
                    defaultValue={
                      workflowActionCrmField.overwrite_existing_value
                    }
                    popupMatchSelectWidth={false}
                    size="small"
                    options={[
                      { label: "Overwrite", value: true },
                      { label: "Write if empty", value: false },
                    ]}
                    onSelect={(value) =>
                      debouncedHandleSubmit({ overwrite_existing_value: value })
                    }
                  />
                </Col>
              )}
            </Row>
          </Col>

          {!isRequired && (
            <Col>
              <Button
                loading={isRemoving}
                danger
                type="text"
                size="small"
                key="remove-field"
                icon={<CloseCircleOutlined />}
                onClick={async () => {
                  try {
                    setIsRemoving(true);

                    await deleteWorkflowActionCrmField(
                      workflowUuid,
                      workflowState.uuid,
                      workflowActionCrmField.uuid
                    );

                    await afterSave();
                    await mutateWorkflowActionCrmFields();

                    message.success(`"${crm_field_label}" Removed`);
                  } catch (e) {
                    message.error(e.message);
                  }

                  setIsRemoving(false);
                }}
              >
                Remove
              </Button>
            </Col>
          )}
        </Row>
      }
    >
      {[
        CRMFieldType.Id,
        CRMFieldType.Boolean,
        CRMFieldType.Picklist,
        CRMFieldType.Multipicklist,
        CRMFieldType.Reference,
      ].includes(crm_field_type) ? (
        <DynamicVariableSelect
          fieldType={crm_field_type}
          staticOptions={staticOptions}
          value={value}
          onChange={(value) => {
            debouncedHandleSubmit({ crm_field_value: value });
            setValue(value);
          }}
        />
      ) : (
        <DynamicVariableTextarea
          showBorder={false}
          value={value}
          onChange={(value) => {
            debouncedHandleSubmit({ crm_field_value: value });
            setValue(value);
          }}
        />
      )}
    </Card>
  );
}
