import React from "react";
import { Integration } from "../../util/enums";
import { CRMProvider } from "../../util/types";
import { faHubspot, faSalesforce } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getCRMColor = (crm: CRMProvider): string => {
  switch (crm) {
    case Integration.Salesforce:
      return "#0D9DDA";
    case Integration.Hubspot:
      return "#F57722";
  }
};

interface CRMIconProps {
  crm: CRMProvider;
  color?: string;
  style?: React.CSSProperties;
}

export function CRMIcon({ crm, style, color }: CRMIconProps) {
  let icon;

  switch (crm) {
    case Integration.Salesforce:
      icon = faSalesforce;
      break;
    case Integration.Hubspot:
      icon = faHubspot;
      break;
  }

  return <FontAwesomeIcon icon={icon} style={style} color={color} />;
}
