import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  FieldConfiguration,
  FieldConfigurationPlaygroundResponse,
  FieldDefinition,
  Meeting,
} from "../../../../util/types";

export type TestMeeting = {
  meeting: Meeting;
  summary: string;
};

export interface PlaygroundSlice {
  fieldToTest: FieldConfiguration;
  fieldDefinition: FieldDefinition;
  meetingToTest: TestMeeting;
  meetingNameFilter: string;
  meetingsSelectedTeamFilter: string[]; // Team UUIDS
  meetingsPage: number;
  meetingsPageSize: number;
  currentFieldConfigurationTab: string;
  latestLibraryTestResult: {
    [testConfigurationUuid: string]: FieldConfigurationPlaygroundResponse;
  };
}

const initialState: PlaygroundSlice = {
  fieldToTest: null,
  fieldDefinition: null,
  meetingToTest: null,
  meetingNameFilter: "",
  meetingsSelectedTeamFilter: [],
  meetingsPage: 0,
  meetingsPageSize: 10,
  currentFieldConfigurationTab: "",
  latestLibraryTestResult: {},
};

export const playgroundSlice = createSlice({
  name: "playground",
  initialState,
  reducers: {
    setFieldToTest: (
      state,
      action: PayloadAction<{
        fieldConfiguration: FieldConfiguration;
        fieldDefinition: FieldDefinition;
      }>
    ) => {
      state.fieldDefinition = action.payload.fieldDefinition;
      state.fieldToTest = action.payload.fieldConfiguration;
    },
    setMeetingToTest: (state, action: PayloadAction<TestMeeting>) => {
      state.meetingToTest = action.payload;
    },
    setMeetingsSelectedTeamFilter: (state, action: PayloadAction<string[]>) => {
      state.meetingsSelectedTeamFilter = action.payload;
    },
    setMeetingNameFilter: (state, action: PayloadAction<string>) => {
      state.meetingNameFilter = action.payload;
    },
    setMeetingsPage: (state, action: PayloadAction<number>) => {
      state.meetingsPage = action.payload;
    },
    setMeetingsPageSize: (state, action: PayloadAction<number>) => {
      state.meetingsPageSize = action.payload;
    },
    setCurrentFieldConfigurationTab: (state, action: PayloadAction<string>) => {
      state.currentFieldConfigurationTab = action.payload;
    },
    setLatestLibraryTestResult: (
      state,
      action: PayloadAction<{
        [testConfigurationUuid: string]: FieldConfigurationPlaygroundResponse;
      }>
    ) => {
      state.latestLibraryTestResult = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFieldToTest,
  setMeetingToTest,
  setMeetingNameFilter,
  setMeetingsPageSize,
  setMeetingsPage,
  setMeetingsSelectedTeamFilter,
  setCurrentFieldConfigurationTab,
  setLatestLibraryTestResult,
} = playgroundSlice.actions;

export default playgroundSlice.reducer;
