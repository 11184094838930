import React from "react";
import { useSlackUsers } from "../../../../../util/data_hooks";
import { DynamicVariableSelect } from "./dynamic_variables/DynamicVariableSelect";
import SkeletonInput from "antd/es/skeleton/Input";

export function SlackUsersSelect({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  const { slackUsers, isLoading } = useSlackUsers();

  if (isLoading) return <SkeletonInput active style={{ width: 500 }} />;

  return (
    <DynamicVariableSelect
      isMultiSelect
      staticOptions={slackUsers.map(({ user_id, username, real_name }) => {
        return {
          label: `${real_name} (${username})`,
          value: user_id,
        };
      })}
      value={value}
      onChange={onChange}
    />
  );
}
