import { useEffect, useState } from "react";
import { useTeams } from "../../../../../util/data_hooks";
import { teamColors } from "../../../../../helpers/teamColorHelper";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  setMeetingsSelectedTeamFilter,
  setMeetingNameFilter,
  setMeetingsPage,
  setMeetingsPageSize,
} from "../../../../redux/features/PlaygroundFieldTesting/playgroundSlice";
import { useDebounce } from "@uidotdev/usehooks";

export function usePlaygroundMeetingFilter() {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const { teams, isLoading: teamsLoading } = useTeams();
  const assignedTeamColors = teamColors(teams);
  const teamOptions = teams.map((team) => ({
    value: team.uuid,
    label: team.name,
  }));
  const teamFilterUuids = useAppSelector(
    (state) => state.playground.meetingsSelectedTeamFilter
  );

  const meetingNameFilter = useAppSelector(
    (state) => state.playground.meetingNameFilter
  );
  const debouncedMeetingName = useDebounce(meetingNameFilter, 600);

  const page = useAppSelector((state) => state.playground.meetingsPage);
  const pageSize = useAppSelector((state) => state.playground.meetingsPageSize);

  const updateTeamFilterUuids = (teamUuids: string[]) => {
    updateMeetingsPage(0);
    dispatch(setMeetingsSelectedTeamFilter(teamUuids));
  };

  const updateMeetingNameFilter = (meetingName: string) => {
    updateMeetingsPage(0);
    dispatch(setMeetingNameFilter(meetingName));
  };

  const updateMeetingsPage = (page: number) => {
    dispatch(setMeetingsPage(page));
  };
  const updateMeetingsPageSize = (pageSize: number) => {
    dispatch(setMeetingsPageSize(pageSize));
  };

  const resetFilters = () => {
    updateMeetingNameFilter("");
    updateTeamFilterUuids([]);
  };

  useEffect(() => {
    setFilterCount(
      (meetingNameFilter?.length ? 1 : 0) +
        (teamFilterUuids?.length > 0 ? 1 : 0)
    );
  }, [meetingNameFilter, teamFilterUuids]);

  return {
    open,
    setOpen,
    filterCount,
    setFilterCount,
    meetingNameFilter,
    debouncedMeetingName,
    updateMeetingNameFilter,
    teams,
    teamsLoading,
    assignedTeamColors,
    teamOptions,
    teamFilterUuids,
    updateTeamFilterUuids,
    page,
    pageSize,
    updateMeetingsPage,
    updateMeetingsPageSize,
    resetFilters,
  };
}
