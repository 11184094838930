import React from "react";
import {
  FieldTestOutcomeConfigurationDetail,
  FieldTestOutcomeConfigurationWithMeeting,
} from "../../../../../../util/types";
import { message, Modal } from "antd";
import { useAppSelector } from "../../../../../redux/hooks";
import { TestOutcomeConfigForm } from "./TestOutcomeConfigForm";
import {
  createFieldTestConfiguration,
  updateFieldTestConfiguration,
} from "../../../../../../util/api";
import { get } from "lodash";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  afterSave?: () => void;
  initialTestConfiguration: FieldTestOutcomeConfigurationDetail;
  shouldEdit: boolean;
  fieldTestOutcomeConfiguration?: FieldTestOutcomeConfigurationWithMeeting;
}

export function TestOutcomeConfigCreateModal({
  open,
  setOpen,
  afterSave,
  initialTestConfiguration,
  shouldEdit,
  fieldTestOutcomeConfiguration,
}: Props) {
  const meetingToTest = useAppSelector(
    (state) => state.playground.meetingToTest
  );
  const fieldToTest = useAppSelector((state) => state.playground.fieldToTest);

  const createTestConfig = async (
    testConfig: FieldTestOutcomeConfigurationDetail
  ) => {
    try {
      await createFieldTestConfiguration(
        fieldToTest?.uuid,
        meetingToTest?.meeting.uuid,
        testConfig
      );
      message.success(`${meetingToTest?.meeting.topic} test added to library`);
      if (afterSave) {
        afterSave();
      }
      setOpen(false);
    } catch (error) {
      if (get(error, "response.status") < 500) {
        message.error(
          get(
            error,
            "response.data.message",
            "Oops! Something went wrong. Please try again."
          )
        );
      } else {
        message.error("Oops! Something went wrong. Please try again.");
      }
    }
  };

  const editTestConfig = async (
    testConfig: FieldTestOutcomeConfigurationDetail
  ) => {
    try {
      await updateFieldTestConfiguration(
        fieldToTest?.uuid,
        fieldTestOutcomeConfiguration?.uuid,
        testConfig
      );
      message.success(
        `${fieldTestOutcomeConfiguration?.meeting.topic} test updated`
      );
      if (afterSave) {
        afterSave();
      }
      setOpen(false);
    } catch (error) {
      if (get(error, "response.status") < 500) {
        message.error(
          get(
            error,
            "response.data.message",
            "Oops! Something went wrong. Please try again."
          )
        );
      } else {
        message.error("Oops! Something went wrong. Please try again.");
      }
    }
  };

  return (
    <Modal
      title={`New Test - ${meetingToTest?.meeting.topic}`}
      onCancel={() => setOpen(false)}
      open={open}
      destroyOnClose
      width={"75%"}
      footer={false}
    >
      <TestOutcomeConfigForm
        isCreate={true}
        testConfiguration={initialTestConfiguration}
        handleSave={shouldEdit ? editTestConfig : createTestConfig}
        handleCancel={() => setOpen(false)}
      />
    </Modal>
  );
}
